import { cva } from 'class-variance-authority';

import { cn } from '@/utils/cn';

import { Wrapper } from '../Wrapper';

interface DividerProps {
  className?: string;
  type?: 'horizontal' | 'vertical';
}

const dividerVariants = cva('', {
  variants: {
    variant: {
      horizontal: 'w-full h-px',
      vertical: 'w-px h-full',
    },
  },
  defaultVariants: {
    variant: 'horizontal',
  },
});

export const Divider = ({ className = '', type = 'horizontal' }: DividerProps) => (
  <Wrapper className={cn(dividerVariants({ variant: type, className }))} />
);
