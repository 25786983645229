/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import ReactGA from 'react-ga4';
import { getRemoteConfigValue } from '@/firebase/remoteConfig';
import { RemoteConfig } from 'firebase/remote-config';
import { useSetupContext } from '../contexts/SetupContext';
import { useEmptyAdsContext } from '../contexts/EmptyAdsContext';
import { useDisplayedAdsContext } from '../contexts/DisplayedAdsContext';

declare global {
  interface Window {
    googletag?: any;
    adSlots?: any;
  }
}

export const useGoogleAdSetup = () => {
  const { setGoogleAdSetupReady } = useSetupContext();
  const { setDisplayedAdSlots } = useDisplayedAdsContext();
  const { setEmptyAdSlots } = useEmptyAdsContext();

  const logAnalyticsEvent = (pageName: string) => {
    ReactGA.event('ad_impression_internal', {
      ad_provider: 'GAM',
      ad_placement: pageName,
    });
  };

  const fetchGoogleAdSetUp = async (config: RemoteConfig, pageName: string) => {
    const adZone = `AdZone${pageName}_in_feed`;

    try {
      const googleInit = await getRemoteConfigValue(config, 'AdProviderInitGAM');
      const fetchRatioInit = await getRemoteConfigValue(config, 'AdGlobalGAMFetchRatio');
      const mobileScalingValue = await getRemoteConfigValue(config, 'AdGlobalGAMMobileFetchRatio');
      const adZoneData = await getRemoteConfigValue(config, adZone);
      const parsedGoogleInit = JSON.parse(googleInit as string);
      const parsedAdZoneData = JSON.parse(adZoneData as string);
      const parsedFetchRatioInit = JSON.parse(fetchRatioInit as string);
      const feedAdCount = Number(parsedAdZoneData?.count) || 1;
      const { networkId, pageList } = parsedGoogleInit;
      const googletag = window.googletag || { cmd: [] };
      const filteredPageList = pageList.filter(
        (item: any) => item.pageName.startsWith(pageName) && !item.pageName.endsWith('in_feed'),
      );
      const feedAdBanner = pageList.find(
        (item: any) => item.pageName.endsWith('in_feed') && item.pageName.startsWith(pageName),
      );

      // set ads slots
      googletag?.cmd.push(() => {
        filteredPageList.forEach((banner: any) =>
          googletag
            .defineSlot(`${networkId}/${banner.unitName}`, banner.sizes, banner.pageName)
            .addService(googletag.pubads()),
        );

        if (feedAdCount !== undefined && !!feedAdBanner) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < feedAdCount; i++) {
            googletag
              .defineSlot(`${networkId}/${feedAdBanner.unitName}`, feedAdBanner.sizes, `${feedAdBanner.pageName}-${i}`)
              .addService(googletag.pubads());
          }
        }

        // set GAM lazy loading
        googletag.pubads().enableLazyLoad({
          fetchMarginPercent: parsedFetchRatioInit.fetchMarginPercent,
          renderMarginPercent: parsedFetchRatioInit.renderMarginPercent,
          mobileScaling: mobileScalingValue,
        });

        // check GAM render
        googletag.pubads().addEventListener('slotRenderEnded', (event: any) => {
          const { slot } = event;
          if (event.isEmpty) {
            setDisplayedAdSlots((prevState: string[]) => {
              const newState = prevState.filter((item) => item !== slot.getSlotElementId());
              return newState;
            });
            setEmptyAdSlots((prevState: string[]) => [...prevState, slot.getSlotElementId()]);
            console.log('NO_GAM_AD:', slot.getSlotElementId());
            return;
          }
          if (!event.isEmpty) {
            setDisplayedAdSlots((prevState: string[]) => [...prevState, slot.getSlotElementId()]);
            console.log('ON_GAM_AD:', slot.getSlotElementId());
            logAnalyticsEvent(slot.getSlotElementId());
          }
        });

        googletag.enableServices();
      });

      setGoogleAdSetupReady(true);
    } catch (error) {
      console.error(error);
    }
  };

  return { fetchGoogleAdSetUp };
};
