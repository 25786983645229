import '@styles/globals.css';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import dayjs from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import { useSessionStorage } from 'usehooks-ts';
import { AxiosError, AxiosResponse } from 'axios';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { useEffect } from 'react';

import { getUserInfo } from '@/api/user/user';
import { usePopularCommentStore } from '@/store/usePopularCommentStore';
import { useAuthCookies } from '@/hooks/useAuthCookies';
import { useLogout } from '@/hooks/useLogout';
import { useUserCookies } from '@/hooks/useUserCookies';
import { AdsContextProvider } from '@/components/organisms/AdCompanion';
import { Layout } from '@/components/organisms/Layout';
import { Didomi } from '@/components/organisms/Didomi';
import { IInterstitialTriggers } from '@/interfaces/IInterstitialTriggers';
import { AppContextProvider } from '@/context/AppContext';
import Script from 'next/script';
import { useRouter } from 'next/router';
import * as fbq from '../../scripts/fpixel';

dayjs.extend(isTodayPlugin);

const MyApp = ({ Component, pageProps, ...appProps }: AppProps) => {
  const { logout } = useLogout();
  const { setUserCookies } = useUserCookies();
  const [, setUserRole] = useSessionStorage('user-role', 1);
  const [isSessionStartWithPostPage, setSessionStartWithPostPage] = useSessionStorage<number | null>(
    'isSessionStartWithPostPage',
    null,
  );
  const [interstitialTriggers, setInterstitialTriggers] = useSessionStorage<IInterstitialTriggers | null>(
    'interstitial-ad',
    null,
  );
  const { posts, setPosts } = usePopularCommentStore((state) => state);
  const { token } = useAuthCookies();
  const isUserLoggedIn = !!token;
  const pagesWithoutLayout = [`/apple-app-site-association`, `/rss`].includes(appProps.router.pathname);
  const canonicalUrl = `${process.env.APP_URL}${appProps.router.asPath}`;
  const router = useRouter();

  useEffect(() => {
    if (!isUserLoggedIn) return;

    const getUserInformation = async () => {
      try {
        const response = await getUserInfo();
        const { id, email, username, createdAt, role, avatar } = response;
        const userAvatar = avatar !== null ? avatar.formats.medium.url : '';
        setUserRole(role.id);
        setUserCookies(id, email, username, createdAt, false, userAvatar);
      } catch (error) {
        console.log(error);
        const { response } = error as AxiosError;
        const { status } = response as AxiosResponse;

        if (status === 401) {
          logout();
        }
      }
    };

    void getUserInformation();
  }, []);

  useEffect(() => {
    // init firebase GA
    const analytics = getAnalytics();
    setUserProperties(analytics, { user_logged: isUserLoggedIn ? 1 : 0 });
  }, []);

  // clean popularCommentStore
  useEffect(() => {
    if (posts?.length > 20) {
      setPosts(posts.slice(10));
    }
  }, [posts]);

  useEffect(() => {
    if (!interstitialTriggers) {
      setInterstitialTriggers({ prevShownTime: '', currentImpressionsCount: 0, currentActionCount: 0 });
    }
  }, [interstitialTriggers]);

  useEffect(() => {
    fbq.pageView();

    const handleRouteChange = () => {
      fbq.pageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (isSessionStartWithPostPage === null) {
      if (appProps.router.pathname === '/post/[...slug]') {
        setSessionStartWithPostPage(1);
        return;
      }
      setSessionStartWithPostPage(0);
    }
  }, [isSessionStartWithPostPage]);

  const getContent = () => {
    if (pagesWithoutLayout) return <Component {...pageProps} />;

    return (
      <AppContextProvider>
        <AdsContextProvider>
          <Layout>
            <Script
              id="fb-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${fbq.FB_PIXEL_ID});
              `,
              }}
            />
            <Component {...pageProps} />
          </Layout>
        </AdsContextProvider>
      </AppContextProvider>
    );
  };

  return (
    <>
      <Didomi />
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="icon" href="/images/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="twitter:widgets:theme" content="dark" />
      </Head>
      <main>{getContent()}</main>
    </>
  );
};

export default MyApp;
