import { StateCreator, create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { DataItem } from '@/api/posts';

import { createHookStore } from './index';

export interface ILocalCommentStore {
  posts: DataItem[];
  setPosts: (value: DataItem[]) => void;
}

const initialState = {
  posts: [],
  setPosts: () => {},
};

const emptyState: StateCreator<ILocalCommentStore> = (set) => ({
  ...initialState,
  setPosts: (posts) => set({ posts }),
});

export const popularCommentStore = create(
  devtools(persist(emptyState, { name: 'popular-comment-persist' }), { name: 'popularCommentStore' }),
);

export const usePopularCommentStore = createHookStore<ILocalCommentStore>(popularCommentStore, initialState);
