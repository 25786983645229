import { useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';

import { getFilterTags } from '@/api/tags/tags';
import { ITagData } from '@/api/tags/interfaces';

interface ITagsProps {
  tagsData: ITagData;
  searchValue: string;
}

export const useTags = ({ tagsData, searchValue }: ITagsProps) => {
  const debouncedSearchValue = useDebounce<string>(searchValue, 300);
  const [tagsState, setTagsState] = useState<ITagData>(tagsData);

  useUpdateEffect(() => {
    if (!debouncedSearchValue) {
      setTagsState(tagsData);
      return;
    }

    const fetchTagsByName = async () => {
      try {
        const response = await getFilterTags(debouncedSearchValue.toLowerCase());
        setTagsState(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    void fetchTagsByName();
  }, [debouncedSearchValue]);

  return { tagsState };
};
