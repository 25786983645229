import Image from 'next/image';
import Link from 'next/link';

import { cn } from '@/utils/cn';

import { Wrapper } from '../Wrapper';

interface AvatarComponentProps {
  link?: string;
  avatar: string;
  className: string;
}

export const AvatarComponent = ({ link, avatar, className }: AvatarComponentProps) => (
  <Link href={link || ''}>
    <Wrapper className={cn('flex items-center justify-center cursor-pointer relative', className)}>
      <Image
        alt="avatar"
        src={!avatar ? '/icons/avatar-green.svg' : avatar}
        className="w-full h-full object-cover rounded-full"
        layout="fill"
        itemProp="image"
      />
    </Wrapper>
  </Link>
);
