import { useMediaQuery } from 'usehooks-ts';

export const useScreensWidth = () => {
  const wideDesktop = useMediaQuery('(min-width: 1600px)');
  const desktop = useMediaQuery('(min-width: 1366px)');
  const laptop = useMediaQuery('(min-width: 1156px)');
  const iPad = useMediaQuery('(min-width: 1080px)');
  const regular = useMediaQuery('(min-width: 720px');
  const md = useMediaQuery('(min-width: 950px)');
  const sm = useMediaQuery('(min-width: 600px)');
  const xs = useMediaQuery('(min-width: 475px)');
  const xxs = useMediaQuery('(min-width: 360px)');

  return { wideDesktop, desktop, laptop, iPad, md, regular, sm, xs, xxs };
};
