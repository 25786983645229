import clsx from 'clsx';

interface ILoginIconProps {
  className?: string;
}

export const LoginIcon = ({ className }: ILoginIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={clsx('stroke-white', className)}
  >
    <path
      d="M9.16667 13.8333L5.83333 10.4999M5.83333 10.4999L9.16667 7.16659M5.83333 10.4999H17.5M13.3333 13.8333V14.6666C13.3333 15.3296 13.0699 15.9655 12.6011 16.4344C12.1323 16.9032 11.4964 17.1666 10.8333 17.1666H5C4.33696 17.1666 3.70107 16.9032 3.23223 16.4344C2.76339 15.9655 2.5 15.3296 2.5 14.6666L2.5 6.33325C2.5 5.67021 2.76339 5.03433 3.23223 4.56549C3.70107 4.09664 4.33696 3.83325 5 3.83325L10.8333 3.83325C11.4964 3.83325 12.1323 4.09664 12.6011 4.56549C13.0699 5.03433 13.3333 5.67021 13.3333 6.33325V7.16659"
      stroke="#18181B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
