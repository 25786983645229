/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */

export const refreshSmartAd = (id: string) => {
  const sas = (window as any).sas || {};

  sas?.cmd?.push(() => {
    console.log('smart_refresh_start', id);
    sas?.refresh(id);
  });
};
