import { ReactNode, useEffect, useState } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

import { SmartAds } from '../SmartAds';
import { useAdZoneValue } from '../hooks';

interface IFeedAdsProps {
  index: number;
  pageName: string;
  adSeparator?: ReactNode;
  adPlaceholder?: ReactNode;
  isConsentNoticeReady: boolean;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const FeedAds = ({
  index,
  pageName,
  adSeparator,
  adPlaceholder,
  isConsentNoticeReady,
  getRemoteConfigValue,
}: IFeedAdsProps) => {
  const { feedAdConfig } = useAdZoneValue({ pageName, getRemoteConfigValue });
  const [isAdVisible, setAdVisible] = useState<boolean>(false);

  const generateIndexesWithAd = (
    count: number | undefined,
    offset: number | undefined,
    startIndex: number | undefined,
  ) => {
    if (!count || !offset || !startIndex) return;

    const postIndex = startIndex - 1;

    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      result.push(postIndex + i * offset);
    }

    // eslint-disable-next-line consistent-return
    return result;
  };

  const indexesWithAds = generateIndexesWithAd(feedAdConfig?.count, feedAdConfig?.offset, feedAdConfig?.start);

  // eslint-disable-next-line consistent-return
  const getFeedIndex = (feedIndex: number) => {
    const indexPosition = indexesWithAds?.indexOf(feedIndex);
    if (indexPosition !== -1) {
      return indexPosition?.toString();
    }
  };

  const feedIndex = getFeedIndex(index);

  useEffect(() => {
    if (!feedIndex || !feedAdConfig?.prefetch || !feedIndex) return;
    const prefetchIndex = indexesWithAds?.find((adIndex) => adIndex === index);
    const indexNumber = Number(prefetchIndex) - feedAdConfig.prefetch;

    if (indexNumber < 1) {
      setAdVisible(true);
      return;
    }

    const targetElement = document.getElementById(`feedComponent_${indexNumber}`);

    if (!targetElement) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAdVisible(true);
        }
      });
    });

    observer.observe(targetElement);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [feedIndex, !feedAdConfig?.prefetch, index]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {indexesWithAds?.includes(index) && (
        <SmartAds
          variant="inFeed"
          pageName={pageName}
          feedIndex={feedIndex}
          isAdVisible={isAdVisible}
          adSeparator={adSeparator}
          adPlaceholder={adPlaceholder}
          isConsentNoticeReady={isConsentNoticeReady}
          getRemoteConfigValue={getRemoteConfigValue}
        />
      )}
      {!indexesWithAds?.includes(index) && index !== 0 && <div />}
    </>
  );
};
