import { createContext, type FC, type ReactNode, useContext, useMemo, useState, SetStateAction, Dispatch } from 'react';

export type EmptyAdsContextState = {
  emptyAdSlots: string[];
  setEmptyAdSlots: Dispatch<SetStateAction<string[]>>;
};

export type EmptyAdsProviderProps = {
  children: ReactNode;
};

const EmptyAdsContext = createContext<EmptyAdsContextState | null>(null);

export const EmptySmartAdsProvider: FC<EmptyAdsProviderProps> = ({ children }) => {
  const [emptyAdSlots, setEmptyAdSlots] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      emptyAdSlots,
      setEmptyAdSlots,
    }),
    [emptyAdSlots, setEmptyAdSlots],
  );

  return <EmptyAdsContext.Provider value={value}>{children}</EmptyAdsContext.Provider>;
};

EmptyAdsContext.displayName = 'EmptyAdsContext';

export const useEmptyAdsContext = () => {
  const context = useContext(EmptyAdsContext);

  if (context === null) throw new Error('useEmptyAdsContext must be used within a EmptyAdsContextProvider');

  return context;
};
