import clsx from 'clsx';

interface ILogoutIconProps {
  className?: string;
}

export const LogoutIcon = ({ className }: ILogoutIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={clsx('stroke-white', className)}
  >
    <path
      d="M14.1667 13.8334L17.5 10.5M17.5 10.5L14.1667 7.16671M17.5 10.5H5.83333M10.8333 13.8334V14.6667C10.8333 15.3297 10.5699 15.9656 10.1011 16.4345C9.63226 16.9033 8.99637 17.1667 8.33333 17.1667H5C4.33696 17.1667 3.70107 16.9033 3.23223 16.4345C2.76339 15.9656 2.5 15.3297 2.5 14.6667L2.5 6.33337C2.5 5.67033 2.76339 5.03445 3.23223 4.56561C3.70107 4.09677 4.33696 3.83337 5 3.83337L8.33333 3.83337C8.99637 3.83337 9.63226 4.09677 10.1011 4.56561C10.5699 5.03445 10.8333 5.67033 10.8333 6.33337V7.16671"
      stroke="#18181B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
