import { createContext, type FC, type ReactNode, useContext, useMemo, useState, SetStateAction, Dispatch } from 'react';

export type DisplayedAdsContextState = {
  displayedAdSlots: string[];
  setDisplayedAdSlots: Dispatch<SetStateAction<string[]>>;
};

export type DisplayedAdsProviderProps = {
  children: ReactNode;
};

const DisplayedAdsContext = createContext<DisplayedAdsContextState | null>(null);

export const DisplayedAdsProvider: FC<DisplayedAdsProviderProps> = ({ children }) => {
  const [displayedAdSlots, setDisplayedAdSlots] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      displayedAdSlots,
      setDisplayedAdSlots,
    }),
    [displayedAdSlots, setDisplayedAdSlots],
  );

  return <DisplayedAdsContext.Provider value={value}>{children}</DisplayedAdsContext.Provider>;
};

DisplayedAdsContext.displayName = 'DisplayedAdsContext';

export const useDisplayedAdsContext = () => {
  const context = useContext(DisplayedAdsContext);

  if (context === null) throw new Error('useDisplayedAdsContext must be used within a DisplayedAdsContextProvider');

  return context;
};
