import { useEffect, useState } from 'react';

import { useAuthCookies } from './useAuthCookies';

export const useIsUserLoggedIn = () => {
  const { token } = useAuthCookies();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  return { isLoggedIn };
};
