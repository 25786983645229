import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type ButtonHTMLAttributes } from 'react';

import { cn } from '@/utils/cn';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    fullWidth?: boolean;
  };

const buttonVariants = cva('', {
  variants: {
    variant: {
      default: 'text-white font-medium py-2.5 px-6 h-fit',
      outlined: 'border border-white rounded-md text-white font-medium py-2.5 px-6 h-fit',
      contained: 'bg-primary-600 text-white font-medium py-2.5 px-6 h-fit rounded-md',
      delete: 'border border-red-500 rounded-md text-red-500',
      underlined: 'text-primary-500 hover:underline',
      icon: 'text-white rounded-full w-fit h-fit p-2',
      text: 'text-primary-600 w-fit h-fit',
      filterButton:
        'text-sm regular:text-base font-semibold text-white hover:bg-gray-900 hover:ease-in hover:duration-150 gap-2 px-2 regular:px-3 py-2 regular:py-3 rounded outline-none flex items-center',
      uploadDelete: 'flex flex-row items-center  text-xs text-gray-400 hover:opacity-70 gap-1',
      uploadAdd: 'border rounded-md py-2.5 px-6 h-fit font-medium text-gray-400 border-gray-600 bg-gray-800',
      accountDelete: 'text-sm font-medium text-gray-950 hover:opacity-80 bg-red-500 rounded-lg py-2.5',
      white: 'text-sm font-medium text-gray-800 hover:opacity-90 py-2.5 px-6 bg-white rounded-lg h-fit',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', fullWidth = false, type = 'button', ...rest }, ref) => (
    <button
      ref={ref}
      className={cn(buttonVariants({ variant, className }), { 'w-full': fullWidth })}
      type={type}
      {...rest}
    />
  ),
);

Button.displayName = 'Button';

export { Button, buttonVariants };
