import { useEffect } from 'react';
import { useIsClient } from 'usehooks-ts';
import { DidomiSDK } from '@didomi/react';
import ReactGA from 'react-ga4';

import { didomiNoticeStore } from '@/store/useDidomiNoticeStore';

import { config } from './config';
import { openDidomiConsentNotice } from './utils';

export const Didomi = () => {
  const isClient = useIsClient();
  const { isNoticeReady, setNoticeReady } = didomiNoticeStore.getState();

  const onNoticeReady = () => {
    setNoticeReady(true);
  };

  useEffect(() => {
    if (!isClient) return;

    if (!isNoticeReady) {
      const timer = setTimeout(() => {
        openDidomiConsentNotice();
      }, 1000);

      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timer);
    }
  }, [isNoticeReady, isClient]);

  return (
    <DidomiSDK
      iabVersion={2}
      config={config}
      apiKey={process.env.DIDOMI_API_KEY}
      noticeId={process.env.DIDOMI_NOTICE_ID}
      onNoticeShown={() => {
        ReactGA.event('consent_display');
      }}
      onNoticeClickAgree={() => {
        onNoticeReady();
        ReactGA.event('consent_button_click', { button_type: 'agree' });
      }}
      onNoticeClickMoreInfo={() => {
        onNoticeReady();
        ReactGA.event('consent_button_click', { button_type: 'learn_more' });
      }}
      onPreferencesClickSaveChoices={() => {
        onNoticeReady();
        ReactGA.event('consent_preferences_button_click', { button_type: 'save' });
      }}
      onPreferencesClickAgreeToAll={() => {
        onNoticeReady();
        ReactGA.event('consent_preferences_button_click', { button_type: 'agree_to_all' });
      }}
      onPreferencesClickDisagreeToAll={() => {
        onNoticeReady();
        ReactGA.event('consent_preferences_button_click', { button_type: 'disagree_to_all' });
      }}
      gdprAppliesGlobally
    />
  );
};
