import { IDidomiObject } from '@didomi/react';

declare global {
  interface Window {
    Didomi: IDidomiObject;
    gdprAppliesGlobally: boolean;
  }
}

export const openDidomiConsentNotice = () => {
  if (window) {
    const { Didomi } = window as Window;
    Didomi?.notice?.show();
  }
};
