import { MouseEvent, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import clsx from 'clsx';

import { ICategoryData } from '@/api/categories/interfaces';
import { getCategoryType } from '@/helpers/getCategoryType';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { useScreensWidth } from '@/hooks/useScreensWidth';
import { Wrapper } from '@/components/atoms/Wrapper';

import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { ButtonContainer } from './ButtonContainer';

interface ITagCategorySectionProps {
  isTagPopover?: boolean;
  isBlogPostInFeed?: boolean;
  categories: { data: ICategoryData[] };
  postUrl?: string;
  tags: {
    data: {
      id: number;
      attributes: {
        name: string;
        createdAt: string;
        updatedAt: string;
      };
    }[];
  };
  postId?: number;
  postTitle?: string;
  isSinglePost?: boolean;
  handleClosePopover?: () => void;
}

export const TagCategorySection = ({
  tags,
  postId,
  postUrl,
  postTitle,
  categories,
  isBlogPostInFeed = false,
  isTagPopover = false,
  isSinglePost = false,
  handleClosePopover,
}: ITagCategorySectionProps) => {
  const { md } = useScreensWidth();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const initialState = isTagPopover ? tags.data.slice(0, 10) : tags?.data;
  const [tagsData, setTagsData] = useState(initialState);
  const [categoriesData, setCategoriesData] = useState(categories?.data);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [moreButtonsCount, setMoreButtonsCount] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const isHideTags = !showButtons && !isTagPopover && !isSinglePost;

  const tagUrl = (tag: string, tagId: number) => `/?tag=${tag}&tagId=${tagId}`;
  const categoryUrl = (categoryId: number) => `/categories/${categoryId}`;

  const onFilterItemClick = (
    e: MouseEvent<HTMLElement>,
    tag?: string,
    tagId?: number,
    categoryId?: number,
    categoryName?: string,
  ) => {
    e.stopPropagation();
    if (handleClosePopover) {
      void handleClosePopover();
    }
    if (!isTagPopover) {
      ReactGA.event('post_hashtag_tap', {
        post_id: postId,
        post_title: postTitle,
        tag_id: tagId,
        tag_name: tag,
      });
    }
    if (tagId) {
      ReactGA.event('navi_tag_tap', {
        tag_id: tagId,
        tag_name: tag,
        tap_source: 'post',
      });
    }
    if (categoryId) {
      ReactGA.event('navi_category_tap', {
        category_id: categoryId,
        category_name: categoryName,
      });
    }
    updateInterstitialActionCount();
    window.location.href = categoryId ? categoryUrl(categoryId) : tagUrl(tag as string, tagId as number);
  };

  const navigateToPost = () => {
    if (postUrl) {
      updateInterstitialActionCount();
      window.location.href = postUrl;
    }
  };

  // eslint-disable-next-line consistent-return
  const checkMaxTagsCount = () => {
    const container = ref.current;
    const buttons = container?.querySelectorAll('button') as NodeListOf<HTMLButtonElement>;
    const buttonsGap = 8;
    let totalButtonsWidth = 150;
    let buttonsToFit = 0;

    if (container && buttons) {
      buttons.forEach((button) => {
        const buttonWidth = button.getBoundingClientRect().width + buttonsGap;

        if (totalButtonsWidth + buttonWidth <= container.clientWidth) {
          totalButtonsWidth += buttonWidth;
          buttonsToFit += 1;
        } else {
          // Break out of the loop if adding the next button would exceed the container width
        }
      });

      return buttonsToFit;
    }
  };

  useEffect(() => {
    if (isTagPopover || !md || isSinglePost) return;
    const buttonsCount = checkMaxTagsCount();
    const exceedButtons = tags.data.length + categories.data.length - (buttonsCount || 0);
    const tagsSlice = (buttonsCount || 0) - categories.data.length;
    setMoreButtonsCount(exceedButtons);
    setCategoriesData(categories?.data?.slice(0, buttonsCount));
    setTagsData(tags?.data?.slice(0, tagsSlice));
    setShowButtons(true);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!!categoriesData.length || !!tagsData.length) && (
        <Wrapper
          ref={ref}
          className={clsx(
            'overflow-scroll md:overflow-hidden gap-2',
            isHideTags && 'md:invisible',
            isSinglePost && 'md:flex-wrap',
            isTagPopover && 'flex-wrap',
            isBlogPostInFeed && 'md:flex-wrap',
            'noScrollbar',
          )}
          row
          fullWidth
        >
          {categoriesData?.map((category) => {
            const { name, icon } = category.attributes;
            const iconSource = icon?.data?.attributes?.url;
            const id = category?.id;

            return (
              <ButtonContainer
                key={id}
                name={name}
                icon={iconSource}
                variant={getCategoryType(id)}
                onAuxClick={() => navigateToNewWindow(categoryUrl(id))}
                onClick={(e: MouseEvent<HTMLElement>) => onFilterItemClick(e, undefined, undefined, id, name)}
              />
            );
          })}

          {tagsData?.map((tag) => {
            const splittedTag = tag.attributes.name.split('#');
            const currentTag = splittedTag.length > 1 ? splittedTag[1] : splittedTag[0];
            const { id } = tag;

            return (
              <ButtonContainer
                key={tag.id}
                variant="default"
                name={currentTag}
                onAuxClick={() => navigateToNewWindow(tagUrl(currentTag, id))}
                onClick={(e: MouseEvent<HTMLElement>) => onFilterItemClick(e, currentTag, id)}
                isTag
              />
            );
          })}
          {moreButtonsCount > 0 && (
            <button onClick={navigateToPost}>
              <Wrapper
                className="border-gray-800 hover:border-primary-900 bg-gray-800 hover:bg-primary-900 hover:ease-in hover:duration-150 items-center justify-center px-1.5 gap-1 cursor-pointer max-w-256 h-7 text-white rounded-md border"
                row
              >
                <p className="text-sm truncate">+{moreButtonsCount}</p>
              </Wrapper>
            </button>
          )}
        </Wrapper>
      )}
    </>
  );
};
