import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/utils/cn';

export interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  className?: string;
  row?: boolean;
  style?: { transform: string | undefined; transition: string | undefined };
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => Promise<boolean> | void | Promise<void>;
  onAuxClick?: () => Promise<boolean> | void | Promise<void>;
}

// eslint-disable-next-line react/display-name
export const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  (
    { id, children, className, row = false, fullWidth = false, style, onClick, onMouseEnter, onMouseLeave, ...props },
    ref,
  ) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      className={cn('flex w-fit', row ? 'flex-row' : 'flex-col', fullWidth && 'w-full', className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={style}
      id={id}
      {...props}
    >
      {children}
    </div>
  ),
);
