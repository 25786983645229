/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

import { useAdZoneValue } from './useAdZoneValue';
import { useSetupContext } from '../contexts/SetupContext';
import { useGlobalRefreshRate } from './useGlobalRefreshRate';
import { useDisplayedAdsContext } from '../contexts/DisplayedAdsContext';
import { ISmartAdProviderData, IGoogleAdProviderData, ISmartPageListItem, IGogglePageListItem } from '../interfaces';
import { useEmptyAdsContext } from '../contexts/EmptyAdsContext';

interface IAdCallProps {
  pageName: string;
  feedIndex?: string;
  isConsentNoticeReady: boolean;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const useAdCall = ({ pageName, feedIndex, isConsentNoticeReady, getRemoteConfigValue }: IAdCallProps) => {
  const { globalSmartRefreshRate, globalGoogleRefreshRate, setSmartRefreshInterval, setGoogleRefreshInterval } =
    useGlobalRefreshRate({
      getRemoteConfigValue,
    });
  const { adZoneOrder, adZoneGoogleRefreshRate, adZoneSmartRefreshRate } = useAdZoneValue({
    pageName,
    getRemoteConfigValue,
  });
  const { firebaseConfig } = useSetupContext();
  const { emptyAdSlots } = useEmptyAdsContext();
  const { displayedAdSlots, setDisplayedAdSlots } = useDisplayedAdsContext();
  const [smartAdId, setSmartAdId] = useState<string>('');
  const [googleAdId, setGoogleAdId] = useState<string>('');
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [smartAdData, setSmartAdData] = useState<ISmartAdProviderData | null>(null);
  const [googleAdData, setGoogleAdData] = useState<IGoogleAdProviderData | null>(null);
  const smartAdConfig = smartAdData?.pageList?.find((ad: ISmartPageListItem) => ad.pageName === pageName);
  const googleAdConfig = googleAdData?.pageList?.find((ad: IGogglePageListItem) => ad.pageName === pageName);
  const smartAd = displayedAdSlots.includes(smartAdId);
  const googleAd = displayedAdSlots.includes(googleAdId);
  const noSmartAd = emptyAdSlots.includes(smartAdId);
  const noGoogleAd = emptyAdSlots.includes(googleAdId);

  const getSmartFeedIndex = () => {
    if (!feedIndex || Number(feedIndex) === 0) return '';

    return `_${Number(feedIndex) + 1}`;
  };

  const getGoogleFeedIndex = () => {
    if (!feedIndex) return '';

    return `-${feedIndex}`;
  };

  const smartIndex = getSmartFeedIndex();
  const googleIndex = getGoogleFeedIndex();

  const smartAdCall = () => {
    const sas = (window as any).sas || {};
    sas.cmd = sas.cmd || [];

    sas?.cmd?.push(() => {
      sas.render(`${smartAdConfig?.formatId}${smartIndex}`);
    });
  };

  const closeSmartAd = () => {
    const sas = (window as any).sas || {};
    sas.clean(smartAdConfig?.formatId);
  };

  const fetchSmartProvider = async (config: RemoteConfig) => {
    try {
      const smartInit = await getRemoteConfigValue(config, 'AdProviderInitSmart');
      const parsedSmartInit = JSON.parse(smartInit as string);
      if (!parsedSmartInit) return;

      setSmartAdData(parsedSmartInit);
      const adsConfig = parsedSmartInit?.pageList?.find((ad: ISmartPageListItem) => ad.pageName === pageName);
      setSmartAdId(`sas_${adsConfig?.formatId}${smartIndex}`);
    } catch (error) {
      console.error(error);
    }
  };

  const googleAdCall = () => {
    const googletag = window.googletag || { cmd: [] };

    googletag.cmd.push(() => {
      googletag.display(`${googleAdConfig?.pageName}${googleIndex}`);
    });
  };

  const closeGoogleAd = () => {
    window.googletag
      .pubads()
      .getSlots()
      .forEach((slot: any) => {
        const id = slot.getSlotElementId();
        if (id === pageName) {
          window.googletag.cmd.push(() => {
            console.log('gam_clear', slot);
            window.googletag.pubads().clear([slot]);
          });
        }
      });
  };

  const removeAdSlot = () => {
    closeGoogleAd();
    closeSmartAd();
    setDisplayedAdSlots((prevState: string[]) => {
      const newState = prevState.filter((item) => item !== smartAdId && item !== googleAdId);
      return newState;
    });
  };

  const fetchGoogleProvider = async (config: RemoteConfig) => {
    try {
      const googleInit = await getRemoteConfigValue(config, 'AdProviderInitGAM');
      const parsedGoogleInit = JSON.parse(googleInit as string);
      if (!parsedGoogleInit) return;

      setGoogleAdData(parsedGoogleInit);
      const adsConfig = parsedGoogleInit?.pageList?.find((ad: IGogglePageListItem) => ad.pageName === pageName);
      setGoogleAdId(`${adsConfig?.pageName}${googleIndex}`);
    } catch (error) {
      console.error(error);
    }
  };

  const adCall = () => {
    const googletag = window.googletag || undefined;

    if (adZoneOrder[0] === 'gam_pagedefined' && !!googletag) {
      googleAdCall();
      return;
    }
    smartAdCall();
  };

  const refreshSmartInterval = () => {
    const refreshInterval = setSmartRefreshInterval(smartAdId, adZoneSmartRefreshRate);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(refreshInterval);
  };

  const refreshGoogleInterval = () => {
    const refreshInterval = setGoogleRefreshInterval(googleAdId, adZoneGoogleRefreshRate, googleAdConfig?.pageName);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(refreshInterval);
  };

  useEffect(() => {
    if (!firebaseConfig || !isConsentNoticeReady) return;

    if (smartAd && !isRefreshing) {
      setIsRefreshing(true);
      refreshSmartInterval();
      return;
    }

    if (googleAd && !isRefreshing) {
      setIsRefreshing(true);
      refreshGoogleInterval();
      return;
    }

    if (!smartAd && !googleAd && !!noSmartAd && !!noGoogleAd && !isRefreshing) {
      setIsRefreshing(true);
      if (adZoneOrder[0] === 'gam_pagedefined') {
        refreshGoogleInterval();
        return;
      }
      refreshSmartInterval();
    }
  }, [
    firebaseConfig,
    globalSmartRefreshRate,
    adZoneSmartRefreshRate,
    adZoneGoogleRefreshRate,
    globalGoogleRefreshRate,
    isConsentNoticeReady,
    smartAd,
    googleAd,
    noSmartAd,
    noGoogleAd,
    isRefreshing,
  ]);

  // Prevent displaying two providers in one ad slot
  useEffect(() => {
    if (smartAd && googleAd) {
      // Remove ad provider depending on zone order
      if (adZoneOrder[0] === 'gam_pagedefined') {
        closeGoogleAd();
        return;
      }
      closeSmartAd();
    }
  }, [smartAd, googleAd]);

  return {
    smartAdId,
    googleAdId,
    fetchSmartProvider,
    fetchGoogleProvider,
    smartAdCall,
    closeSmartAd,
    googleAdCall,
    adCall,
    removeAdSlot,
  };
};
