/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Cookies from 'universal-cookie';

export const useAuthCookies = () => {
  const cookies = new Cookies();
  const token = cookies.get('authData')?.token;

  const setAuthCookies = (authToken: string, isRememberMe: boolean) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);

    cookies.set(
      'authData',
      {
        token: authToken,
        token_type: 'Bearer',
      },
      { path: '/', expires: isRememberMe ? expirationDate : undefined },
    );
  };

  const removeAuthCookies = () => {
    cookies.remove('authData', { path: '/' });
  };

  return { setAuthCookies, removeAuthCookies, token };
};
