import { useRouter } from 'next/router';

export const useAdPageName = () => {
  const router = useRouter();
  const { pathname } = router;

  const getAdBannerName = (adName: string): string => {
    if (pathname === '/') {
      return `home_page_${adName}`;
    }

    if (pathname === '/locker') {
      return `locker_${adName}`;
    }

    if (pathname === '/categories/[categoryId]') {
      return `categories_${adName}`;
    }

    return `inside_post_${adName}`;
  };

  const getAdPageName = () => {
    if (pathname === '/') {
      return `home_page`;
    }

    if (pathname === '/locker') {
      return `locker`;
    }

    if (pathname === '/categories/[categoryId]') {
      return `categories`;
    }

    return `inside_post`;
  };

  return { getAdBannerName, getAdPageName };
};
