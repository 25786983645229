import { ReactNode, forwardRef, useEffect, useState } from 'react';

import { RemoteConfig } from 'firebase/remote-config';
import { cn } from '@/utils/cn';
import { useAdCall, useVideoAutoMutation } from './hooks';
import { BannerVariantType } from './types';

import { AdsContainer } from './AdsContainer';
import { StickyAdBadge } from './StickyAdBadge';
import { useSetupContext } from './contexts/SetupContext';
import { useEmptyAdsContext } from './contexts/EmptyAdsContext';
import { useDisplayedAdsContext } from './contexts/DisplayedAdsContext';
import { AdBadge } from './AdBadge';

interface SmartAdsProps {
  pageName: string;
  className?: string;
  feedIndex?: string;
  isAdVisible?: boolean;
  adSeparator?: ReactNode;
  adPlaceholder?: ReactNode;
  variant: BannerVariantType;
  isConsentNoticeReady: boolean;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const SmartAds = forwardRef<HTMLDivElement, SmartAdsProps>(
  (
    {
      variant,
      pageName,
      adSeparator,
      isAdVisible = true,
      className,
      feedIndex,
      adPlaceholder,
      isConsentNoticeReady,
      getRemoteConfigValue,
    },
    ref,
  ) => {
    const { isSmartAdSetupReady, firebaseConfig, isGoogleAdSetupReady } = useSetupContext();
    const { emptyAdSlots, setEmptyAdSlots } = useEmptyAdsContext();
    const { displayedAdSlots } = useDisplayedAdsContext();
    const {
      smartAdId,
      googleAdId,
      smartAdCall,
      fetchSmartProvider,
      fetchGoogleProvider,
      googleAdCall,
      adCall,
      removeAdSlot,
    } = useAdCall({
      pageName,
      feedIndex,
      isConsentNoticeReady,
      getRemoteConfigValue,
    });
    const [isWaterfall, setWaterfall] = useState<boolean>(true);
    const isPlaceholderVisible = !!adPlaceholder;
    const smartAd = displayedAdSlots.find((item) => item === smartAdId);
    const googleAd = displayedAdSlots.find((item) => item === googleAdId);
    const isAdBadgeVisible = (!!smartAd || !!googleAd) && variant !== 'stickedBanner';
    const isStickyBanner = (!!googleAd || !!smartAd) && variant === 'stickedBanner';

    useEffect(() => {
      if (!firebaseConfig || !isConsentNoticeReady) return;
      void fetchSmartProvider(firebaseConfig);
      void fetchGoogleProvider(firebaseConfig);
    }, [isConsentNoticeReady, firebaseConfig]);

    useEffect(() => {
      if (
        isConsentNoticeReady &&
        smartAdId &&
        googleAdId &&
        isSmartAdSetupReady &&
        isGoogleAdSetupReady &&
        isAdVisible
      ) {
        adCall();
      }
    }, [isConsentNoticeReady, smartAdId, googleAdId, isSmartAdSetupReady, isGoogleAdSetupReady, isAdVisible]);

    // google call if no smart ads
    useEffect(() => {
      const isSmartSlotEmpty = emptyAdSlots.find((item) => item === smartAdId);
      if (isConsentNoticeReady && googleAdId && isGoogleAdSetupReady && !!isSmartSlotEmpty && isWaterfall) {
        setWaterfall(false);
        setEmptyAdSlots((prevState: string[]) => {
          const newState = prevState.filter((item) => item !== smartAdId);
          return newState;
        });
        googleAdCall();
      }
    }, [isConsentNoticeReady, googleAdId, isGoogleAdSetupReady, emptyAdSlots, isWaterfall]);

    // smart call if no google ads
    useEffect(() => {
      const isGoogleSlotEmpty = emptyAdSlots.find((item) => item === googleAdId);

      if (isConsentNoticeReady && smartAdId && isSmartAdSetupReady && !!isGoogleSlotEmpty && isWaterfall) {
        setWaterfall(false);
        setEmptyAdSlots((prevState: string[]) => {
          const newState = prevState.filter((item) => item !== googleAdId);
          return newState;
        });
        smartAdCall();
      }
    }, [isConsentNoticeReady, smartAdId, isSmartAdSetupReady, emptyAdSlots, isWaterfall]);

    useVideoAutoMutation({ smartAdId, variant });

    return (
      <>
        <AdsContainer
          ref={ref}
          variant={variant}
          isPlaceholderVisible={isPlaceholderVisible}
          className={cn(className, isStickyBanner && 'border-t border-gray-800')}
        >
          {isAdBadgeVisible && <AdBadge />}
          <StickyAdBadge isVisible={isStickyBanner} onAdClose={removeAdSlot} />
          <div id={smartAdId} className="z-20" />
          <div id={googleAdId} className={cn('z-20', !googleAd && '[&>div]:!h-0')} />
          {adPlaceholder}
        </AdsContainer>
        {!!adSeparator && adSeparator}
      </>
    );
  },
);
