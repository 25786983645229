/* eslint-disable @next/next/no-html-link-for-pages */
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { ICategoryData } from '@/api/categories/interfaces';
import { ITagData } from '@/api/tags/interfaces';
import { getCategories } from '@api/categories/categories';
import { getTags } from '@api/tags/tags';
import { useAuthCookies } from '@/hooks/useAuthCookies';
import { useDisableBodyScroll } from '@/hooks/useDisableBodyScroll';
import { useSocialLogin } from '@/hooks/useSocialLogin';
import { useUserCookies } from '@/hooks/useUserCookies';
import { sortCategories } from '@/helpers/sortCategories';
import { Wrapper } from '@/components/atoms/Wrapper';

import { InfoContainerDesktop } from './InfoContainerDesktop';
import { InfoContainerMobile } from './InfoContainerMobile';
import { ButtonsGroup } from './ButtonsGroup';
import { NavbarWrapper } from './NavbarWrapper';
import { InfoWrapper } from './InfoWrapper/InfoWrapper';

const DynamicPublishedPostModal = dynamic(() => import('../PublishedPostModal').then((mod) => mod.PublishedPostModal), {
  ssr: false,
});
const DynamicRegisterModal = dynamic(
  () => import('@/components/organisms/RegisterModal').then((mod) => mod.RegisterModal),
  {
    ssr: false,
  },
);
const DynamicLoginModal = dynamic(() => import('@/components/organisms/LoginModal').then((mod) => mod.LoginModal), {
  ssr: false,
});
const DynamicPlannedPostModal = dynamic(() => import('../PlannedPostModal').then((mod) => mod.PlannedPostModal), {
  ssr: false,
});
const DynamicUploadModal = dynamic(() => import('@/components/organisms/UploadModal').then((mod) => mod.UploadModal), {
  ssr: false,
});
const DynamicResetPasswordModal = dynamic(
  () => import('@/components/organisms/ResetPasswordModal').then((mod) => mod.ResetPasswordModal),
  {
    ssr: false,
  },
);
const DynamicManageProfileModal = dynamic(
  () => import('@/components/organisms/ManageProfileModal').then((mod) => mod.ManageProfileModal),
  {
    ssr: false,
  },
);
const DynamicSocialLoginUsernameModal = dynamic(
  () => import('../SocialLoginUsernameModal').then((mod) => mod.SocialLoginUsernameModal),
  {
    ssr: false,
  },
);
const DynamicMenuDropdown = dynamic(() => import('../MenuDropdown').then((mod) => mod.MenuDropdown), {
  ssr: false,
});

const DynamicSidebarMobile = dynamic(() => import('../SidebarMobile').then((mod) => mod.SidebarMobile), {
  ssr: false,
});

const DynamicTagsAndCategoriesSection = dynamic(
  () => import('../TagsAndCategoriesSection').then((mod) => mod.TagsAndCategoriesSection),
  {
    ssr: false,
  },
);

export const Navbar = () => {
  const router = useRouter();
  const { asPath } = router;
  const { token } = useAuthCookies();
  const { id: userId } = useUserCookies();
  const { isUsernameModalVisible, setUsernameModalVisibility } = useSocialLogin();
  const [isMenuDropdownVisible, setMenuDropdownVisibility] = useState<boolean>(false);
  const [isUploadModalVisible, setUploadModalVisibility] = useState<boolean>(false);
  const [isPublishedPostModal, setPublishedPostModal] = useState<boolean>(false);
  const [isResetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
  const [categoriesState, setCategoriesState] = useState<ICategoryData[]>([]);
  const [tagsState, setTagsState] = useState<ITagData | null>(null);
  const [isPlannedPostModal, setPlannedPostModal] = useState<boolean>(false);
  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [isRegisterModal, setRegisterModal] = useState<boolean>(false);
  const [isLoginModal, setLoginModal] = useState<boolean>(false);
  const publishedPostModalPath = asPath.includes('publishedPost');
  const plannedPostModalPath = asPath.includes('plannedPost');
  const registerModalPath = asPath.includes('register');
  const loginModalPath = asPath.includes('login');
  const isUserLoggedIn = !!token && !!userId;

  const openRegisterModal = () => {
    setLoginModal(false);
    setRegisterModal(true);
  };

  const closeRegisterModal = () => {
    setRegisterModal(false);
    if (loginModalPath || registerModalPath) {
      void router.push(asPath.split('#')[0]);
    }
  };

  const openLoginModal = () => {
    setRegisterModal(false);
    setLoginModal(true);
  };

  const closeLoginModal = () => {
    setLoginModal(false);
    if (loginModalPath || registerModalPath) {
      void router.push(asPath.split('#')[0]);
    }
  };

  const handleResetPasswordModal = () => {
    setLoginModal(false);
    setResetPasswordModal(true);
  };

  const handleBackToLogin = () => {
    setLoginModal(true);
    setResetPasswordModal(false);
  };

  const openUploadModal = () => {
    if (isUserLoggedIn) {
      setUploadModalVisibility(true);
      return;
    }

    openLoginModal();
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      const sortedCategories = sortCategories(response?.data);
      setCategoriesState(sortedCategories);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await getTags();
      setTagsState(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void fetchCategories();
    void fetchTags();
  }, []);

  useEffect(() => {
    if (loginModalPath) {
      openLoginModal();
    }
    if (registerModalPath) {
      openRegisterModal();
    }
    if (publishedPostModalPath) {
      setPublishedPostModal(true);
    }
    if (plannedPostModalPath) {
      setPlannedPostModal(true);
    }
  }, [asPath]);

  useDisableBodyScroll(isMenuDropdownVisible);

  return (
    <>
      <DynamicSocialLoginUsernameModal
        isModalVisible={isUsernameModalVisible}
        closeModal={() => setUsernameModalVisibility(false)}
      />
      <DynamicManageProfileModal />
      <DynamicPlannedPostModal
        isModalVisible={isPlannedPostModal}
        closeModal={() => setPlannedPostModal(false)}
        openUploadModal={() => setUploadModalVisibility(true)}
      />
      <DynamicPublishedPostModal
        isModalVisible={isPublishedPostModal}
        closeModal={() => setPublishedPostModal(false)}
      />
      <DynamicLoginModal
        isModalVisible={isLoginModal}
        closeModal={closeLoginModal}
        handleRegisterModal={openRegisterModal}
        handleResetPasswordModal={handleResetPasswordModal}
      />
      <DynamicRegisterModal
        isModalVisible={isRegisterModal}
        closeModal={closeRegisterModal}
        handleLoginModal={openLoginModal}
      />
      <DynamicResetPasswordModal
        isModalVisible={isResetPasswordModal}
        closeModal={() => setResetPasswordModal(false)}
        handleBackToLogin={handleBackToLogin}
      />
      <DynamicUploadModal
        isModalVisible={isUploadModalVisible}
        closeModal={() => setUploadModalVisibility(false)}
        handleCloseMenuDropdown={() => setMenuDropdownVisibility(false)}
      />
      <NavbarWrapper>
        <InfoWrapper>
          <Wrapper className="max-w-laptop px-3 regular:px-6 h-14 md:h-[65px] items-center" row fullWidth>
            <InfoContainerDesktop />
            <InfoContainerMobile setSidebarVisible={setSidebarVisible} />
            <ButtonsGroup
              openMenuDropdown={() => setMenuDropdownVisibility(true)}
              openLoginModal={() => setLoginModal(true)}
              openUploadModal={openUploadModal}
              tagsState={tagsState as ITagData}
            />
          </Wrapper>
        </InfoWrapper>
        <DynamicTagsAndCategoriesSection tagsState={tagsState as ITagData} categoriesState={categoriesState} />
      </NavbarWrapper>
      <DynamicSidebarMobile
        tagsState={tagsState as ITagData}
        categoriesState={categoriesState}
        isSidebarVisible={isSidebarVisible}
        setSidebarVisible={setSidebarVisible}
      />
      <Transition
        show={isMenuDropdownVisible}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 z-50 md:hidden h-full w-full"
      >
        <DynamicMenuDropdown
          onMenuClose={() => setMenuDropdownVisibility(false)}
          openLoginModal={() => setLoginModal(true)}
          openUploadModal={openUploadModal}
        />
      </Transition>
    </>
  );
};
