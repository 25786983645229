export const getCategoryType = (id: number) => {
  if (id === 1) {
    return 'news';
  }
  if (id === 2) {
    return 'mem';
  }
  if (id === 3) {
    return 'survey';
  }
  if (id === 4) {
    return 'video';
  }
  if (id === 5) {
    return 'gallery';
  }

  return 'temporary';
};
