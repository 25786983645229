/* eslint-disable @next/next/no-html-link-for-pages */
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';

import { ITagData } from '@/api/tags/interfaces';
import { useAuthorizedUserImage } from '@/hooks/useAuthorizedUserImage';
import { useIsUserLoggedIn } from '@/hooks/useIsUserLoggedIn';
import { useUserCookies } from '@/hooks/useUserCookies';
import { AvatarComponent } from '@/components/atoms/AvatarComponent';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';

import { TagPopover } from '../../TagPopover';

interface ButtonsGroupProps {
  tagsState: ITagData;
  openLoginModal: () => void;
  openUploadModal: () => void;
  openMenuDropdown: () => void;
}

export const ButtonsGroup = ({ tagsState, openLoginModal, openUploadModal, openMenuDropdown }: ButtonsGroupProps) => {
  const { id } = useUserCookies();
  const { isLoggedIn } = useIsUserLoggedIn();
  const { userImage } = useAuthorizedUserImage();
  const isDesktop = useMediaQuery('(min-width: 950px)');
  const userProfileLink = `/userProfile/${id}`;

  const redirectToUserProfile = () => {
    window.location.href = userProfileLink;
  };

  return (
    <Wrapper className="justify-end box-border h-full gap-3 md:gap-6 items-center min-w-fit" row>
      <TagPopover
        buttonClassName="border-b-3 border-b-transparent hover:border-b-white hover:ease-in hover:duration-150 font-bold text-white justify-center cursor-pointer h-full"
        tagsData={tagsState}
      />
      {isLoggedIn && isLoggedIn !== null && (
        <Button
          variant="outlined"
          onClick={openUploadModal}
          className="border focus:outline-none focus:ring-2 focus:border-primary-600 focus:ring-primary-600 bg-transparent focus:bg-gray-50 hover:bg-gray-50 focus:text-black hover:text-black hidden md:block h-10 w-10 p-0 text-lg"
        >
          +
        </Button>
      )}
      <Wrapper className={clsx(isLoggedIn === null && 'hidden')}>
        {!isLoggedIn ? (
          <Button
            onClick={openLoginModal}
            variant="contained"
            className="px-3 py-2 md:px-5 md:py-2.5 marker:focus:ring-2 focus:ring-primary-300 focus:outline-none hover:bg-primary-700 focus:bg-primary-700 !text-black text-xs md:text-sm"
          >
            Zaloguj
          </Button>
        ) : (
          <button onClick={!isDesktop ? openMenuDropdown : redirectToUserProfile}>
            <AvatarComponent avatar={userImage} className="w-8 h-8 md:w-10 md:h-10 hover:opacity-90" />
          </button>
        )}
      </Wrapper>
    </Wrapper>
  );
};
