import { ReactNode, useEffect, useState } from 'react';

import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';

interface NavbarWrapperProps {
  children: ReactNode;
}

export const NavbarWrapper = ({ children }: NavbarWrapperProps) => {
  const [scrollPosition, setScrollPosition] = useState<string>('bg-opacity-0');

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(
        position > 0
          ? 'md:backdrop-blur-xl md:!navbarDesktopGradient'
          : 'md:bg-opacity-0 md:bg-transparent md:backdrop-blur-none',
      );
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper
      className={cn(
        'items-center justify-center fixed left-0 top-0 z-50 transition-all navbarMobileGradient md:bg-transparent backdrop-blur-md md:backdrop-blur-none',
        scrollPosition,
      )}
      fullWidth
    >
      {children}
    </Wrapper>
  );
};
