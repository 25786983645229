import { createContext, type FC, type ReactNode, useContext, useMemo, useState } from 'react';

export type ManageProfileContextState = {
  isManageProfileModal: boolean;
  setManageProfileModal: (value: boolean) => void;
};

export type ManageProfileContextProviderProps = {
  children: ReactNode;
};

const ManageProfileContext = createContext<ManageProfileContextState | null>(null);

export const ManageProfileContextProvider: FC<ManageProfileContextProviderProps> = ({ children }) => {
  const [isManageProfileModal, setManageProfileModal] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isManageProfileModal,
      setManageProfileModal,
    }),
    [isManageProfileModal, setManageProfileModal],
  );

  return <ManageProfileContext.Provider value={value}>{children}</ManageProfileContext.Provider>;
};

ManageProfileContextProvider.displayName = 'ManageProfileContextProvider';

export const useManageProfileContext = () => {
  const context = useContext(ManageProfileContext);

  if (context === null) throw new Error('useManageProfileContext must be used within a ManageProfileContextProvider');

  return context;
};
