import React from 'react';
import { cn } from '@/utils/cn';
import { CloseButton } from '../CloseButton';

interface StickyAdBadgeProps {
  isVisible: boolean;
  onAdClose: () => void;
}

export const StickyAdBadge = ({ isVisible, onAdClose }: StickyAdBadgeProps) => (
  <div
    className={cn(
      'absolute -top-6 right-0 flex bg-gray-800 h-6 pl-2 pr-1 gap-2 items-center rounded-tl-[4px]',
      !isVisible && 'hidden',
    )}
  >
    <p className="text-xxs text-gray-400">Reklama</p>
    <CloseButton onClick={onAdClose} variant="stickyAd" />
  </div>
);
