import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { cn } from '@/utils/cn';

import { Wrapper } from '../Wrapper';

const toastVariants = cva('w-72 lg:w-96 items-center rounded-md p-4 gap-3', {
  variants: {
    variant: {
      success: 'bg-primary-600 text-gray-950',
      error: 'text-white bg-red-500',
    },
  },
  defaultVariants: {
    variant: 'success',
  },
});

interface IToastProps {
  children: ReactNode;
  variant: 'success' | 'error';
  className?: string;
  isShowToast: boolean;
}

export const Toast = ({ isShowToast, variant, className, children }: IToastProps) => (
  <Wrapper className={cn(isShowToast ? 'animate-enter' : 'animate-leave', toastVariants({ variant, className }))} row>
    {children}
  </Wrapper>
);
