import { useState, KeyboardEvent, useEffect, RefObject } from 'react';

import { ISingleTagData } from '@/api/tags/interfaces';

interface IFollowActiveTag {
  ref: RefObject<HTMLDivElement>;
  inputValue: string;
  tags: ISingleTagData[];
  initialCursor: number;
  onTagClick?: (tag: string, tagId: number) => void;
  handleAddTag?: (cursor: number) => void;
}

export const useFollowActiveTag = ({
  inputValue,
  tags,
  ref,
  initialCursor,
  onTagClick,
  handleAddTag,
}: IFollowActiveTag) => {
  const [cursor, setCursor] = useState<number>(initialCursor);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (handleAddTag) {
        handleAddTag(cursor);
      }

      if (onTagClick) {
        if (!inputValue) return;
        const splittedTag = tags[cursor]?.attributes?.name?.split('#');
        const currentTag = splittedTag?.length > 1 ? splittedTag[1] : splittedTag[0];
        const tagId = tags[cursor].id;
        onTagClick(currentTag, tagId);
      }
    }

    if (e.key === 'ArrowUp') {
      if (cursor === initialCursor) return;
      setCursor(cursor - 1);
    }

    if (e.key === 'ArrowDown') {
      if (cursor === tags.length - 1) return;
      setCursor(cursor + 1);
    }
  };

  const followActiveRow = () => {
    const selectedRef = ref?.current?.querySelector('.active');
    if (selectedRef) {
      selectedRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    setCursor(initialCursor);
  }, [inputValue]);

  useEffect(() => {
    followActiveRow();
  }, [cursor]);

  return { onKeyDown, cursor };
};
