import { useEffect } from 'react';
import Script from 'next/script';
import { RemoteConfig } from 'firebase/remote-config';

import { useSmartAdSetup } from './hooks/useSmartAdSetup';
import { useGoogleAdSetup } from './hooks/useGoogleAdSetup';
import { useSetupContext } from './contexts/SetupContext';

interface IAdsSetupProps {
  pageId: number;
  pageName: string;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const AdsSetup = ({ pageId, pageName, getRemoteConfigValue }: IAdsSetupProps) => {
  const { firebaseConfig } = useSetupContext();
  const { fetchSmartAdSetUp } = useSmartAdSetup({ pageId, getRemoteConfigValue });
  const { fetchGoogleAdSetUp } = useGoogleAdSetup();

  useEffect(() => {
    if (!firebaseConfig) return;
    void fetchSmartAdSetUp(firebaseConfig);
  }, [firebaseConfig]);

  useEffect(() => {
    if (!firebaseConfig) return;
    void fetchGoogleAdSetUp(firebaseConfig, pageName);
  }, [firebaseConfig]);

  return (
    <>
      {/* smart setup script */}
      <Script id="ads-setup" type="application/javascript" async>
        {`var sas = sas || {};
    sas.cmd = sas.cmd || [];
    sas.cmd.push(function() {
        sas.setup({ networkid: 2850, domain: "https://www14.smartadserver.com", async: true  });
    });`}
      </Script>
      {/* google setup script */}
      <Script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
    </>
  );
};
