import Image from 'next/image';
import dynamic from 'next/dynamic';
import { Dispatch, SetStateAction } from 'react';

import { Wrapper } from '@/components/atoms/Wrapper';

const DynamicAnimatedLogoButton = dynamic(() => import('./AnimatedLogoButton').then((mod) => mod.AnimatedLogoButton), {
  ssr: false,
});

interface InfoContainerMobileProps {
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
}

export const InfoContainerMobile = ({ setSidebarVisible }: InfoContainerMobileProps) => (
  <Wrapper className="h-full items-center min-w-fit md:hidden gap-2.5" row fullWidth>
    <button onClick={() => setSidebarVisible(true)} className="relative w-7 h-7">
      <Image src="/icons/menu.svg" alt="Menu icon" layout="fill" priority />
    </button>
    <DynamicAnimatedLogoButton />
  </Wrapper>
);
