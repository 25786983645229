import { useEffect } from 'react';

interface IVideoAutoMutationProps {
  smartAdId: string;
  variant: string;
}

export const useVideoAutoMutation = ({ smartAdId, variant }: IVideoAutoMutationProps) => {
  useEffect(() => {
    if (variant !== 'outstreamVideo' || !smartAdId) return;

    const checkIsVideoMuted = () => {
      const parentNode = document.getElementById(smartAdId);
      if (!parentNode) return;

      const videoElement = document.querySelector('video');
      if (videoElement) {
        // If a video element is found, set it to muted by default
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        videoElement.muted === false;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        observer?.disconnect();
      }
    };

    const observer = new MutationObserver(checkIsVideoMuted);
    const adDiv = document.getElementById(smartAdId);
    const config = { childList: true, subtree: true };

    observer?.observe(adDiv as HTMLElement, config);

    // eslint-disable-next-line consistent-return
    return () => {
      observer?.disconnect();
    };
  }, [smartAdId]);
};
