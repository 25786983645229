export const userProfileRoute = (id: string) => `/users/${id}?populate[0]=role&populate[1]=avatar`;
export const userByUsernameRoute = (username: string) => `/users?filters[username]=${username}`;
export const ownProfileRoute = 'users/me?populate[0]=role&populate[1]=avatar';
export const changeUsernameRoute = '/users-permissions/users/change-username';
export const usernameModalShownRoute = '/users-permissions/users/first-modal';
export const deleteAccountRoute = '/users-permissions/users/delete-account';
export const userPostStatsRoute = (id: string) => `/posts/user-posts/${id}`;
export const hasPasswordRoute = '/users-permissions/users/has-password';
export const setPasswordRoute = '/users-permissions/users/set-password';
export const changeEmailRoute = 'users-permissions/users/change-email';
export const changeAvatarRoute = '/users-permissions/users/avatar';
