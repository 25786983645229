import {
  loginRoute,
  registerRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  changePasswordRoute,
  socialLoginRoute,
} from './routes';
import { IAuthData, IChangePasswordData, IForgotPasswordData, IResetPasswordData } from './interfaces';
import { appInstance, authorizedUserInstance } from '../instances';
import { SocialProviderType } from './types';

export const postLogin = async (identifier: string, password: string) => {
  const response = await appInstance.post<IAuthData>(loginRoute, {
    identifier,
    password,
  });

  return response.data;
};

export const postRegister = async (username: string, email: string, password: string) => {
  const response = await appInstance.post<IAuthData>(registerRoute, {
    username,
    email,
    password,
  });

  return response.data;
};

export const postSocialLogin = async (provider: SocialProviderType, token: string | string[]) => {
  const response = await appInstance.get<IAuthData>(socialLoginRoute(provider, token as string));

  return response.data;
};

export const postForgotPassword = async (email: string) => {
  const response = await appInstance.post<IForgotPasswordData>(forgotPasswordRoute, {
    email,
  });

  return response.data;
};

export const postResetPassword = async (data: IResetPasswordData) => {
  const response = await appInstance.post<IResetPasswordData>(resetPasswordRoute, data);

  return response.data;
};

export const postChangePassword = async (data: IChangePasswordData) => {
  const response = await authorizedUserInstance.post<IChangePasswordData>(changePasswordRoute, data);
  return response.data;
};
