import { useEffect, useState } from 'react';

export const useNavbarVisibilityObserver = () => {
  const [isNavbarVisible, setNavbarVisibility] = useState<boolean>(true);
  const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);

  const handleFixedPanelVisibility = (isVisible: boolean, scrollPosition: number) => {
    setNavbarVisibility(isVisible);
    setLastScrollPosition(scrollPosition);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      const positiveScrollPosition = Math.abs(currentScrollPosition);

      // Scrolled down more than 100px, hide the filter panel
      if (currentScrollPosition > lastScrollPosition + 100) {
        handleFixedPanelVisibility(false, positiveScrollPosition);
        return;
      }
      // Scrolled up more than 50px, show the filter panel
      if (currentScrollPosition < lastScrollPosition - 50) {
        handleFixedPanelVisibility(true, positiveScrollPosition);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition]);

  return {
    isNavbarVisible,
  };
};
