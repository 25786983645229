import { useSessionStorage } from 'usehooks-ts';
import ReactGA from 'react-ga4';

import { useUserCookies } from './useUserCookies';
import { useAuthCookies } from './useAuthCookies';
import { useToast } from './useToast';

export const useLogout = () => {
  const [, setSavedComment] = useSessionStorage('savedComment', {});
  const [, setUserRole] = useSessionStorage('user-role', 1);
  const { removeUserCookies } = useUserCookies();
  const { removeAuthCookies } = useAuthCookies();
  const { showLogoutToast } = useToast();

  const logout = () => {
    setUserRole(1);
    setSavedComment({});
    removeAuthCookies();
    removeUserCookies();
    showLogoutToast();
    ReactGA.event('account_sign_in');
    window.location.href = '/';
  };

  return { logout };
};
