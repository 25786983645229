import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useUserCookies } from './useUserCookies';
import { useIsUserLoggedIn } from './useIsUserLoggedIn';

export const useAuthorizedUserImage = () => {
  const router = useRouter();
  const { asPath } = router;
  const { avatar } = useUserCookies();
  const { isLoggedIn } = useIsUserLoggedIn();
  const [image, setImage] = useState<string>('');

  const getAvatarLink = () => {
    if (isLoggedIn && image) {
      return image;
    }
    if (isLoggedIn && !image) {
      return '/icons/avatar-green.svg';
    }
    return '/icons/avatar.svg';
  };

  useEffect(() => {
    setImage(avatar);
  }, [avatar, asPath]);

  const userImage = getAvatarLink();

  return { userImage };
};
