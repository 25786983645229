import { ReactNode } from 'react';

import { useNavbarVisibilityObserver } from '@/hooks/useNavbarVisibilityObserver';
import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';

interface InfoWrapperProps {
  children: ReactNode;
}

export const InfoWrapper = ({ children }: InfoWrapperProps) => {
  const { isNavbarVisible } = useNavbarVisibilityObserver();

  return (
    <Wrapper className={cn('justify-center', !isNavbarVisible && 'hidden md:flex')} fullWidth row>
      {children}
    </Wrapper>
  );
};
