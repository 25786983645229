import { IAvatarData, IChangeEmailData, IHasPassword, IUserPostStatsData, IUserProfileData } from './interfaces';
import {
  userProfileRoute,
  ownProfileRoute,
  userPostStatsRoute,
  changeEmailRoute,
  deleteAccountRoute,
  changeAvatarRoute,
  changeUsernameRoute,
  usernameModalShownRoute,
  userByUsernameRoute,
  setPasswordRoute,
  hasPasswordRoute,
} from './routes';

import { appInstance, authorizedUserInstance } from '../instances';
import { IAuthData } from '../auth';

export const getOwnProfile = async (token: string) => {
  const response = await appInstance.get<IUserProfileData>(ownProfileRoute, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getUserInfo = async () => {
  const response = await authorizedUserInstance.get<IUserProfileData>(ownProfileRoute);
  return response.data;
};

export const getHasPasswordInfo = async () => {
  const response = await authorizedUserInstance.get<IHasPassword>(hasPasswordRoute);
  return response.data;
};

export const getUserByUsername = async (username: string) => {
  const response = await appInstance.get<IUserProfileData[]>(userByUsernameRoute(username));
  return response.data;
};

export const getUserProfile = async (id: string) => {
  const response = await appInstance.get<IUserProfileData>(userProfileRoute(id));
  return response;
};

export const getUserPostStats = async (id: string) => {
  const response = await appInstance.get<IUserPostStatsData>(userPostStatsRoute(id));
  return response;
};

export const postChangeUsername = async (username: string) => {
  const response = await authorizedUserInstance.post<IUserProfileData>(changeUsernameRoute, { username });
  return response.data;
};

export const postSetPassword = async (password: string) => {
  const response = await authorizedUserInstance.post<IAuthData>(setPasswordRoute, { password });
  return response.data;
};

export const postChangeEmail = async (data: IChangeEmailData) => {
  const response = await authorizedUserInstance.post<IChangeEmailData>(changeEmailRoute, data);
  return response.data;
};

export const postIsUsernameModalShown = async () => {
  const response = await authorizedUserInstance.post(usernameModalShownRoute);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};

export const postChangeAvatar = async (file: Blob | string) => {
  const form = new FormData();
  form.append('files.avatar', file);

  const response = await authorizedUserInstance.post<IAvatarData>(changeAvatarRoute, form);

  return response.data;
};

export const deleteAccount = async () => {
  const response = await authorizedUserInstance.delete(deleteAccountRoute);
  return response;
};
