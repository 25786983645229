import { Fragment, useState, ChangeEvent, useRef, useEffect } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Popover, Transition } from '@headlessui/react';
import { useOnClickOutside } from 'usehooks-ts';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import Image from 'next/image';
import clsx from 'clsx';

import { ITagData } from '@/api/tags/interfaces';
import { closeIcon } from '@/components/icons/CloseIcon';
import { useFollowActiveTag } from '@/hooks/useFollowActiveTag';
import { useTags } from '@/hooks/useTags';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Input } from '@/components/atoms/Input';
import { TagCategorySection } from '@/components/molecules/TagCategorySection';

interface ITagPopoverProps {
  tagsData: ITagData;
  buttonClassName: string;
}

export const TagPopover = ({ tagsData, buttonClassName }: ITagPopoverProps) => {
  const router = useRouter();
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { tagsState } = useTags({ tagsData, searchValue });
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const tagsRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef(null);
  const tagUrl = (tag: string, tagId: number) => `/?tag=${tag}&tagId=${tagId}`;

  const onPopoverClose = () => {
    setShowPopover(false);
    setSearchValue('');
  };

  const onTagClick = (tag: string, tagId: number) => {
    ReactGA.event('search_tag_tap', { tag: tag === searchValue ? 'matched' : 'suggested' });
    void router.push(tagUrl(tag, tagId));
    void onPopoverClose();
  };

  const toggle = () => {
    setShowPopover((state) => !state);
    setSearchValue('');
  };

  const { onKeyDown, cursor } = useFollowActiveTag({
    ref: tagsRef,
    inputValue: searchValue,
    initialCursor: 0,
    tags: tagsState?.data,
    onTagClick,
  });

  useScrollPosition(({ currPos }) => {
    if (showPopover) {
      setScrollPosition(currPos.y);
      if (scrollPosition && scrollPosition !== currPos.y) {
        onPopoverClose();
      }
    }
  });

  useEffect(() => {
    if (isTyping && showPopover) {
      ReactGA.event('search_field_tap');
      return;
    }
    setIsTyping(false);
  }, [isTyping, showPopover]);

  useOnClickOutside(popoverRef, onPopoverClose);

  return (
    <Popover className="min-w-fit h-full !relative">
      <Popover.Button
        className={clsx(buttonClassName, 'flex items-center min-w-full outline-none !border-b-transparent gap-2')}
        onClick={toggle}
      >
        <Wrapper className="relative w-7 h-7 md:w-6 md:h-6 mt-0.5 md:mt-0">
          <Image src="/icons/navbar-search.svg" alt="Search icon" layout="fill" />
        </Wrapper>
      </Popover.Button>
      <Transition
        as={Fragment}
        show={showPopover}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel
          className="absolute z-20 top-16 -right-10 md:right-1/2 md:translate-x-1/2 w-screen max-w-300 custom400:max-w-355 md:max-w-465 flex justify-end md:justify-center"
          ref={popoverRef}
        >
          <Wrapper className="border border-gray-800 rounded-lg shadow-lg overflow-hidden bg-gray-950 p-5">
            <Wrapper className="items-center justify-between mb-4" fullWidth row>
              <h2 className="text-lg text-gray-400">Tematy</h2>
              <button
                onClick={onPopoverClose}
                className="text-xs text-gray-600 hover:text-gray-900 bg-transparent hover:bg-gray-200 inline-flex items-center rounded-lg p-1.5 h-8"
                type="button"
              >
                {closeIcon}
              </button>
            </Wrapper>
            <Wrapper className="relative" fullWidth>
              <Input
                id="search"
                type="text"
                value={searchValue}
                onKeyDown={onKeyDown}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchValue(e.target.value);
                  setIsTyping(true);
                }}
                placeholder="Szukaj tematu"
                maxLength={256}
                className={clsx('!border-gray-600', searchValue && '!rounded-t-lg !rounded-b-none')}
                autofocus
                iconLeft={
                  <Wrapper className="text-gray-400 self-center ml-2">
                    <MagnifyingGlassIcon width={16} height={16} />
                  </Wrapper>
                }
                iconRight={
                  !!searchValue && (
                    <Wrapper className="text-gray-400 cursor-pointer self-center ml-1 mr-2">
                      <XMarkIcon onClick={() => setSearchValue('')} width={16} height={16} />
                    </Wrapper>
                  )
                }
              />
              {!!searchValue && (
                <Wrapper
                  className={clsx(
                    'border border-t-0 border-gray-600 rounded-b-lg absolute top-52 overflow-hidden bg-gray-900 max-h-120 mr-3',
                    'customScroll',
                  )}
                  fullWidth
                >
                  <Wrapper ref={tagsRef} className="p-1 m-1 overflow-auto w-auto">
                    {tagsState?.data?.length ? (
                      tagsState?.data?.map((tag, index) => {
                        const splittedTag = tag.attributes.name.split('#');
                        const currentTag = splittedTag.length > 1 ? splittedTag[1] : splittedTag[0];
                        const { id } = tag;
                        return (
                          <Wrapper
                            onAuxClick={() => navigateToNewWindow(tagUrl(currentTag, id))}
                            onClick={() => onTagClick(currentTag, id)}
                            key={id}
                            className={clsx(
                              'hover:bg-gray-800 gap-2 pl-2 py-0.5 cursor-pointer items-center rounded-lg',
                              index === cursor && 'bg-gray-800 active',
                            )}
                            fullWidth
                            row
                          >
                            <p className="font-bold text-primary-600">#</p>
                            <p className="text-sm text-gray-300 overflow-x-hidden">{currentTag}</p>
                          </Wrapper>
                        );
                      })
                    ) : (
                      <p className="text-sm text-gray-300">brak wyników</p>
                    )}
                  </Wrapper>
                </Wrapper>
              )}
            </Wrapper>
            <Wrapper className="gap-4 mt-4" fullWidth>
              <p className="text-sm font-medium text-gray-400">Popularne tematy</p>
              <TagCategorySection
                handleClosePopover={onPopoverClose}
                categories={{ data: [] }}
                tags={tagsData}
                isTagPopover
              />
            </Wrapper>
          </Wrapper>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
