import { StateCreator, create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createHookStore } from './index';

export interface INoticeStore {
  isNoticeReady: boolean;
  setNoticeReady: (value: boolean) => void;
}

const initialState = {
  isNoticeReady: false,
  setNoticeReady: () => {},
};

const emptyState: StateCreator<INoticeStore> = (set) => ({
  ...initialState,
  setNoticeReady: (isNoticeReady) => set({ isNoticeReady }),
});

export const didomiNoticeStore = create(
  devtools(persist(emptyState, { name: 'didomi-notice-persist' }), { name: 'didomiNoticeStore' }),
);
export const useDidomiNoticeStore = createHookStore<INoticeStore>(didomiNoticeStore, initialState);
