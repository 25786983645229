import { MouseEvent } from 'react';
import { cva } from 'class-variance-authority';
import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';

interface IButtonContainerProps {
  name: string;
  isTag?: boolean;
  icon?: string;
  variant: 'default' | 'news' | 'mem' | 'survey' | 'video' | 'gallery' | 'temporary';
  onAuxClick: () => void;
  onClick: (e: MouseEvent<HTMLElement>) => void;
}

const buttonVariants = cva(
  'hover:ease-in hover:duration-150 items-center justify-center px-1.5 gap-1 cursor-pointer max-w-256 h-7 text-white rounded-md border',
  {
    variants: {
      variant: {
        default: 'border-gray-800 hover:border-primary-900 bg-gray-800 hover:bg-primary-900',
        news: 'hover:text-orange-400 border-orange-400 bg-yellow-950',
        mem: 'hover:text-lime-300 border-lime-300 bg-lime-900',
        survey: 'hover:text-teal-500 border-teal-500 bg-blueGray-800',
        video: 'hover:text-blue-500 border-blue-500 bg-slate-800',
        gallery: 'hover:text-rose-400 border-rose-400 bg-stone-800',
        temporary: 'hover:text-customRed-500 border-customRed-500 bg-pink-950',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export const ButtonContainer = ({ name, icon, isTag = false, variant, onClick, onAuxClick }: IButtonContainerProps) => (
  <button onAuxClick={onAuxClick} onClick={onClick} className="min-w-fit">
    <Wrapper className={buttonVariants({ variant })} row fullWidth>
      {!!icon && <Image src={icon} alt={name} width={16} height={16} />}
      {isTag && <p className="font-bold text-primary-600">#</p>}
      <p className="text-sm truncate">{name}</p>
    </Wrapper>
  </button>
);
