import { ReactNode } from 'react';

import { ManageProfileContextProvider } from '@/components/organisms/ManageProfileModal/ManageProfileContext';

export type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => (
  <ManageProfileContextProvider>{children}</ManageProfileContextProvider>
);
