import clsx from 'clsx';

interface ICommentsProps {
  className?: string;
}

export const Comments = ({ className }: ICommentsProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    className={clsx('stroke-white', className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 5.51953H17C17.5304 5.51953 18.0391 5.73025 18.4142 6.10532C18.7893 6.48039 19 6.9891 19 7.51953V13.5195C19 14.05 18.7893 14.5587 18.4142 14.9337C18.0391 15.3088 17.5304 15.5195 17 15.5195H15V19.5195L11 15.5195H7C6.73733 15.5198 6.47719 15.4682 6.23453 15.3676C5.99187 15.267 5.77148 15.1195 5.586 14.9335M5.586 14.9335L9 11.5195H13C13.5304 11.5195 14.0391 11.3088 14.4142 10.9337C14.7893 10.5587 15 10.05 15 9.51953V3.51953C15 2.9891 14.7893 2.48039 14.4142 2.10532C14.0391 1.73024 13.5304 1.51953 13 1.51953H3C2.46957 1.51953 1.96086 1.73024 1.58579 2.10532C1.21071 2.48039 1 2.9891 1 3.51953V9.51953C1 10.05 1.21071 10.5587 1.58579 10.9337C1.96086 11.3088 2.46957 11.5195 3 11.5195H5V15.5195L5.586 14.9335Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
