/* eslint-disable @next/next/inline-script-id */
import { forwardRef, ReactNode } from 'react';

import { cn } from '@/utils/cn';
import { BannerVariantType } from '../types';

import styles from './AdsContainer.module.css';

interface AdsContainerProps {
  children: ReactNode;
  variant: BannerVariantType;
  isPlaceholderVisible: boolean;
  className?: string;
}

export const AdsContainer = forwardRef<HTMLDivElement, AdsContainerProps>(
  ({ children, variant, isPlaceholderVisible, className }, ref) => {
    const defaultContainer = variant !== 'stickedBanner' && variant !== 'outstreamVideo' && styles.container;
    const stickyContainer = variant === 'stickedBanner' && styles.stickyContainer;
    const videoContainer = variant === 'outstreamVideo' && styles.videoContainer;

    return (
      <div className="flex flex-col items-center w-full relative">
        <div
          ref={ref}
          className={cn(
            'flex flex-col items-center',
            defaultContainer,
            stickyContainer,
            videoContainer,
            styles[variant],
            !isPlaceholderVisible && '!w-fit',
            isPlaceholderVisible && 'w-full',
            variant !== 'stickedBanner' && 'p-4 pt-6',
            className,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);
