import { FC, HTMLProps, ReactNode, Ref, useEffect, useRef } from 'react';

import { cn } from '@/utils/cn';

import { Wrapper } from '../Wrapper';

interface IInput {
  id: string;
  label?: string;
  error?: boolean;
  className?: string;
  inputClassName?: string;
  autofocus?: boolean;
  errorMessage?: string;
  containerClassName?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  containerRef?: Ref<HTMLDivElement>;
}

export const Input: FC<IInput & HTMLProps<HTMLInputElement>> = ({
  id,
  label,
  error,
  errorMessage,
  className = '',
  inputClassName,
  autofocus = false,
  containerClassName,
  iconLeft,
  iconRight,
  containerRef,
  ...props
}: IInput) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperFocus =
    'border rounded-lg focus:ring-0 placeholder:text-gray-400 bg-gray-900 mt-2 h-11 focus-within:border-primary-600 border-gray-600 text-white';
  const wrapperError =
    'border rounded-lg focus:ring-0 placeholder:text-gray-400 bg-gray-900 mt-2 h-11 [&>div>input]:placeholder-red-500 border-red-500 text-red-500';
  const wrapperNormal = error ? wrapperError : wrapperFocus;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autofocus) {
      const timer = setTimeout(() => {
        inputRef?.current?.click();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [autofocus]);

  return (
    <Wrapper className={cn('w-auto', containerClassName)}>
      {!!label && (
        <label htmlFor={id} className="text-sm font-medium text-white">
          {label}
        </label>
      )}
      <Wrapper ref={containerRef} className={cn('w-auto', wrapperNormal, className)}>
        <Wrapper className="relative items-center h-full" fullWidth row>
          {iconLeft}
          <input
            id={id}
            className={cn(
              'focus:ring-0 focus:border-transparent border-none rounded-lg outline-none disabled:cursor-not-allowed text-sm truncate bg-transparent w-full h-full px-3',
              inputClassName,
              'inputOverride',
            )}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autofocus}
            autoComplete="off"
            ref={inputRef}
            {...props}
          />
          {iconRight}
        </Wrapper>
      </Wrapper>
      {!!errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
    </Wrapper>
  );
};
