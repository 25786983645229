import Image from 'next/image';

interface NavbarDownloadLinkProps {
  iconSource: string;
  link: string;
  alt: string;
}

export const NavbarDownloadLink = ({ iconSource, link, alt }: NavbarDownloadLinkProps) => (
  <a
    href={link}
    className="rounded-[4px] border border-gray-600 flex hover:opacity-70 p-1 bg-gray-900"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image alt={alt} src={iconSource} width={16} height={16} />
  </a>
);
