import { categoryPopularTagsRoute, filterTagRoute, singleTagRoute, tagsRoute } from './routes';
import { ICategoryTagData, ITag, ITagData } from './interfaces';

import { appInstance } from '../instances';

export const getTags = async () => {
  const response = await appInstance.get<ITagData>(tagsRoute);
  return response;
};

export const getFilterTags = async (tagName: string) => {
  const response = await appInstance.get<ITagData>(filterTagRoute(tagName));
  return response;
};

export const getSingleTag = async (id: string) => {
  if (id === undefined) return undefined;
  const response = await appInstance.get<ITag>(singleTagRoute(id));
  return response;
};

export const getCategoryPopularTags = async (categoryId: string | string[] | undefined) => {
  const response = await appInstance.get<ICategoryTagData[]>(categoryPopularTagsRoute(categoryId));
  return response;
};
