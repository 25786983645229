import { cva } from 'class-variance-authority';

import { closeIcon } from '@/components/icons/CloseIcon';
import { cn } from '@/utils/cn';

export type CloseButtonType = 'interstitialAd' | 'stickyAd';

interface CloseButtonProps {
  variant: CloseButtonType;
  className?: string;
  onClick: () => void;
}

const closeButtonVariants = cva('inline-flex items-center bg-transparent outline-none rounded-lg text-sm', {
  variants: {
    variant: {
      interstitialAd: 'p-1 z-50',
      stickyAd: 'w-4 h-4 text-gray-400',
    },
  },
  defaultVariants: {
    variant: 'interstitialAd',
  },
});

export const CloseButton = ({ variant, className, onClick }: CloseButtonProps) => (
  <button type="button" className={cn(closeButtonVariants({ variant, className }))} onClick={onClick}>
    {closeIcon}
  </button>
);
