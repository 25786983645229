import { useEffect, useState } from 'react';
import create from 'zustand';

export function createHookStore<State>(store: ReturnType<typeof create>, initialState: State) {
  function useStore<T>(selector: (state: State) => T) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    const state = store<T>(selector as any);
    const [isHydrated, setHydrated] = useState<boolean>(false);

    useEffect(() => {
      setHydrated(true);
    }, []);

    return isHydrated ? state : selector(initialState);
  }

  return useStore;
}
