/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import ReactGA from 'react-ga4';
import { RemoteConfig } from 'firebase/remote-config';
import { useUpdateInterstitialTriggers } from './useUpdateInterstitialTriggers';
import { useEmptyAdsContext } from '../contexts/EmptyAdsContext';
import { useSetupContext } from '../contexts/SetupContext';
import { useDisplayedAdsContext } from '../contexts/DisplayedAdsContext';

interface ISmartAdSetupProps {
  pageId: number;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

interface PageListItem {
  pageName: string;
  pageId: number;
  formatId: number;
  isLazy: boolean;
}

interface IAdResponse {
  formatId: number;
  tagId: string;
}

export const useSmartAdSetup = ({ pageId, getRemoteConfigValue }: ISmartAdSetupProps) => {
  const { updateInterstitialDisplayInfo } = useUpdateInterstitialTriggers();
  const { setSmartAdSetupReady, setInterstitialAd } = useSetupContext();
  const { setDisplayedAdSlots } = useDisplayedAdsContext();
  const { setEmptyAdSlots } = useEmptyAdsContext();

  const logAnalyticsEvent = (pageName: string) => {
    ReactGA.event('ad_impression_internal', {
      ad_provider: 'Smart',
      ad_placement: pageName,
    });
  };

  const fetchSmartAdSetUp = async (config: RemoteConfig) => {
    try {
      const smartInit = await getRemoteConfigValue(config, 'AdProviderInitSmart');
      const desktopFetchRation = await getRemoteConfigValue(config, 'AdGlobalSmartFetchRatio');
      const mobileFetchRation = await getRemoteConfigValue(config, 'AdGlobalSmartMobileFetchRatio');
      const parsedSmartInit = JSON.parse(smartInit as string);

      if (!parsedSmartInit) return;
      const elements = parsedSmartInit?.pageList.filter(({ isLazy }: PageListItem) => isLazy);
      const lazyFormatIds = [...new Set(elements.map((element: any) => element.formatId))];
      const formatIds: number[] = [];
      parsedSmartInit?.pageList.forEach((item: { formatId: number }) => {
        if (!formatIds.includes(item.formatId)) {
          formatIds.push(item.formatId);
        }
      });

      const formatsList = formatIds.map((formatId) => {
        return { id: formatId };
      });

      const sas = (window as any).sas || {};
      sas.cmd = sas.cmd || [];

      // lazy loading setup
      sas.cmd.push(() => {
        sas.enableLazyLoading({
          fetchRatio: desktopFetchRation,
          mobileFetchRatio: mobileFetchRation,
          formats: lazyFormatIds,
        });
      });

      // onecall setup
      sas.cmd.push(() => {
        sas.call(
          'onecall',
          {
            siteId: 617672,
            pageId,
            formats: formatsList,
            target: '',
          },
          {
            onAd: (data: IAdResponse) => {
              if (data.formatId) {
                setDisplayedAdSlots((prevState: string[]) => [...prevState, data.tagId]);
                if (data.formatId === 126453) {
                  setInterstitialAd(true);
                  updateInterstitialDisplayInfo();
                }
                const adObject = parsedSmartInit?.pageList.filter(
                  (item: PageListItem) => item.pageId === pageId && item.formatId === data.formatId,
                );
                const pageName = adObject?.[0].pageName;

                if (pageName) {
                  logAnalyticsEvent(pageName);
                }
                console.log(`ON_SMART_AD: formatId: ${data.formatId}, tagId:${data.tagId}`);
              }
            },
            onNoad: (data: IAdResponse) => {
              if (data.formatId) {
                setDisplayedAdSlots((prevState: string[]) => {
                  const newState = prevState.filter((item) => item !== data.tagId);
                  return newState;
                });
                setEmptyAdSlots((prevState: string[]) => [...prevState, data.tagId]);
                console.log(`NO_SMART_AD: formatId: ${data.formatId}, tagId:${data.tagId}`);
              }
            },
          },
        );
      });

      // We should provide lazy ad formats before loading ads. adSetupSignal provides a signal to the ad component that the ad is ready to be loaded.
      setSmartAdSetupReady(true);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fetchSmartAdSetUp,
  };
};
