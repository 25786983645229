import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';

interface SocialMediaButtonsProps {
  className?: string;
  buttonClassName?: string;
}

export const SocialMediaButtons = ({ className, buttonClassName }: SocialMediaButtonsProps) => {
  const socialMediaConfig = [
    {
      id: 0,
      source: `/icons/facebook-white.svg`,
      alt: 'facebook-logo',
      link: 'https://www.facebook.com/FangolPl/',
    },
    {
      id: 1,
      source: `/icons/instagram-white.svg`,
      alt: 'instagram-logo',
      link: 'https://www.instagram.com/fangolpl/?hl=en',
    },
    { id: 2, source: `/icons/twitter-white.svg`, alt: 'twitter-logo', link: 'https://twitter.com/FangolPL' },
    {
      id: 3,
      source: `/icons/youtube-white.svg`,
      alt: 'youtube-logo',
      link: 'https://www.youtube.com/@pilkawskrocie',
    },
    { id: 4, source: `/icons/tiktok-white.svg`, alt: 'tiktok-logo', link: 'https://www.tiktok.com/@fangol' },
  ];

  return (
    <Wrapper className={cn('gap-5', className)} row>
      {socialMediaConfig.map(({ id, source, alt, link }) => (
        <a
          key={id}
          href={link}
          className={cn('hover:opacity-70 cursor-pointer relative w-6 h-6', buttonClassName)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={source} alt={alt} layout="fill" />
        </a>
      ))}
    </Wrapper>
  );
};
