import { useSessionStorage } from 'usehooks-ts';

import { IInterstitialTriggers } from '@/interfaces/IInterstitialTriggers';

export const useUpdateInterstitialTriggers = () => {
  const [interstitialTriggers, setInterstitialTriggers] = useSessionStorage<IInterstitialTriggers>('interstitial-ad', {
    prevShownTime: '',
    currentImpressionsCount: 0,
    currentActionCount: 0,
  });

  const updateInterstitialDisplayInfo = () => {
    if (interstitialTriggers !== null) {
      setInterstitialTriggers({
        currentActionCount: 0,
        prevShownTime: new Date(),
        currentImpressionsCount: interstitialTriggers.currentImpressionsCount + 1,
      });
    }
  };

  const updateInterstitialActionCount = () => {
    if (interstitialTriggers !== null) {
      setInterstitialTriggers({
        ...interstitialTriggers,
        currentActionCount: interstitialTriggers.currentActionCount + 1,
      });
    }
  };

  return { updateInterstitialDisplayInfo, updateInterstitialActionCount };
};
