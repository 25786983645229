export const config = {
  theme: {
    color: '#31C46C',
    linkColor: '#31C46C',
    buttons: {
      regularButtons: {
        backgroundColor: '#ffffff',
        textColor: '#353535',
        borderColor: '#D6D6D6',
        borderWidth: '1px',
        borderRadius: '8px',
      },
      highlightButtons: {
        backgroundColor: '#31C46C',
        textColor: '#ffffff',
        borderRadius: '8px',
      },
    },
    css: `
      #didomi-host {
        font-family: 'Inter' !important;
      }

      #didomi-host .didomi-popup-backdrop {
        --tw-bg-opacity: 0.8;
        background-color: rgb(9 9 11 / var(--tw-bg-opacity));
      }
    
      #didomi-host .didomi-exterior-border{
        border-color: transparent !important;
      }

      #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing {
        border-radius: 8px;
      }

      #didomi-host .didomi-popup-container {
        border-color: transparent !important;
      }
      
      #didomi-host :focus {
          outline: none;
      }

      #didomi-host .didomi-popup-notice .didomi-popup-notice-buttons .didomi-components-button {
        height: 44px !important;
        min-width: 150px !important;
        font-size: 13px !important;
        line-height: 24px;
      }

      #didomi-host .didomi-components-radio__option {
        border-radius:8px;
        box-shadow:none;
      }

      #didomi-host .didomi-consent-popup-preferences-purposes .didomi-consent-popup-view-vendors-list .didomi-consent-popup-view-vendors-list-link {
        border-radius:8px;
        box-shadow:none;
      }

      #didomi-host .didomi-popup-notice .didomi-popup-notice-text p span {
        color: #7B7D7E !important;
      }

      #didomi-host .didomi-notice-data-processing-container .didomi-notice-data-processing-title {
        color: #7B7D7E !important;
      }

      #didomi-host .didomi-notice-data-processing-container .didomi-notice-data-processing-list {
        color: #7B7D7E !important;
        font-weight: 500 !important;
      }
      `,
  },
};
