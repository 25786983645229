import { appInstance } from '../instances';
import { ICategoriesData, ISingleCategoryData } from './interfaces';
import { categoriesRoute, singleCategoryRoute } from './routes';

export const getCategories = async () => {
  const response = await appInstance.get<ICategoriesData>(categoriesRoute);
  return response.data;
};

export const getSingleCategory = async (categoryId: string | string[] | undefined) => {
  const response = await appInstance.get<ISingleCategoryData>(singleCategoryRoute(categoryId));
  return response.data;
};
