import clsx from 'clsx';

interface ILockClosedProps {
  className?: string;
}

export const LockClosed = ({ className }: ILockClosedProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={clsx('stroke-white', className)}
  >
    <path
      d="M9.99967 13V14.6667M4.99967 18H14.9997C15.4417 18 15.8656 17.8244 16.1782 17.5118C16.4907 17.1993 16.6663 16.7754 16.6663 16.3333V11.3333C16.6663 10.8913 16.4907 10.4674 16.1782 10.1548C15.8656 9.84226 15.4417 9.66667 14.9997 9.66667H4.99967C4.55765 9.66667 4.13372 9.84226 3.82116 10.1548C3.5086 10.4674 3.33301 10.8913 3.33301 11.3333L3.33301 16.3333C3.33301 16.7754 3.5086 17.1993 3.82116 17.5118C4.13372 17.8244 4.55765 18 4.99967 18ZM13.333 9.66667V6.33333C13.333 5.44928 12.9818 4.60143 12.3567 3.97631C11.7316 3.35119 10.8837 3 9.99967 3C9.11562 3 8.26777 3.35119 7.64265 3.97631C7.01753 4.60143 6.66634 5.44928 6.66634 6.33333V9.66667H13.333Z"
      stroke="#18181B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
