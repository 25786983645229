import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useSessionStorage } from 'usehooks-ts';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';

import { SocialProviderType } from '@/api/auth/types';
import { postSocialLogin } from '@/api/auth/auth';
import { getOwnProfile, postIsUsernameModalShown } from '@/api/user/user';

import { useUserCookies } from './useUserCookies';
import { useAuthCookies } from './useAuthCookies';
import { useToast } from './useToast';

export const useSocialLogin = () => {
  const router = useRouter();
  const { setUserCookies } = useUserCookies();
  const { setAuthCookies } = useAuthCookies();
  const { showSuccessLoginToast } = useToast();
  const [, setUserRole] = useSessionStorage('user-role', 1);
  const [savedComment] = useSessionStorage<any>('savedComment', {});
  const [savedPopularComment] = useSessionStorage<any>('savedPopularComment', {});
  const [isHydrated, setHydrated] = useState<boolean>(false);
  const [isUsernameModalVisible, setUsernameModalVisibility] = useState<boolean>(false);
  const { query, asPath } = router;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id_token, access_token, code } = query;
  const { postId, slug, commentId } = savedComment;
  const { commentsPostId, url } = savedPopularComment;
  const commentsRef = commentId === null ? `?ref=comments` : '';

  const showUsernameModal = async () => {
    setUsernameModalVisibility(true);
    try {
      await postIsUsernameModalShown();
    } catch (error) {
      console.log(error);
    }
  };

  const loginWithSocialProvider = async (provider: SocialProviderType, token: string | string[]) => {
    try {
      const { jwt } = await postSocialLogin(provider, token);
      const response = await getOwnProfile(jwt);
      const { id, email, username, createdAt, avatar, isModalShown, role } = response.data;
      const userAvatar = avatar !== null ? avatar.formats.medium.url : '';
      setUserRole(role?.id);
      setAuthCookies(jwt, false);
      setUserCookies(id, email, username, createdAt, false, userAvatar);
      showSuccessLoginToast();
      ReactGA.event('account_sign_in');
      if (!isModalShown) {
        void showUsernameModal();
      }
      if (commentsPostId && url) {
        void router.replace(`${url}`);
        return;
      }
      if (postId) {
        void router.replace(`/post/${postId}/${slug || ''}${commentsRef}`);
        return;
      }
      void router.replace('/');
    } catch (error) {
      console.log(error);
      const { response } = error as AxiosError;
      const { status } = response as AxiosResponse;

      if (status === 400) {
        void router.replace('/');
      }
    }
  };

  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (!isHydrated) return;
    if (!id_token && !access_token && !code) return;

    if (code) {
      void loginWithSocialProvider('apple', code);
      return;
    }

    if (id_token) {
      const googleToken = asPath.split('/?id_token=')[1];
      void loginWithSocialProvider('google', googleToken);
      return;
    }

    if (access_token) {
      void loginWithSocialProvider('facebook', access_token);
    }
  }, [id_token, access_token, isHydrated]);

  return {
    isUsernameModalVisible,
    setUsernameModalVisibility,
  };
};
