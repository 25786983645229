import toast from 'react-hot-toast';
import Image from 'next/image';

import { commentToastConfig } from '@/components/organisms/Comments/config';
import { Toast } from '@/components/atoms/Toast';
import { Divider } from '@/components/atoms/Divider';
import { LoginIcon } from '@/components/icons/LoginIcon';
import { LogoutIcon } from '@/components/icons/LogoutIcon';
import { LockClosed } from '@/components/icons/LockClosed';
import { Comments } from '@/components/icons/Comments';
import { TrashIcon } from '@/components/icons/TrashIcon';

export const useToast = () => {
  const randomToastIndex = Math.floor(Math.random() * commentToastConfig.length);
  const randomToast = commentToastConfig[randomToastIndex];

  const showSuccessLoginToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <LoginIcon className="w-5 h-5 !stroke-gray-950" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Jesteś zalogowany!</p>
          <Image src="/icons/soccer.png" alt="soccer" width={20} height={20} />
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showLogoutToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <LogoutIcon className="w-5 h-5 !stroke-gray-950" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Wylogowano</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showErrorToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="error">
          Coś poszło nie tak, spróbuj ponownie.
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showDeleteCommentToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <TrashIcon className="w-5 h-5 !stroke-gray-950 stroke-2" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Komentarz usunięty</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showHideCommentToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <TrashIcon className="w-5 h-5 !stroke-gray-950 stroke-2" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Komentarz ukryty</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showBlockCommentToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <LockClosed className="w-5 h-5 !stroke-gray-950" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Komentarz zablokowany</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showCommentReportToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <Image src="/icons/shield-check.svg" alt="shield-check" width={20} height={20} />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Dziękujemy za zgłoszenie!</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showContactFormToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <p className="text-sm">Wiadomość została wysłana</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showChangePasswordToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <p className="text-sm">Hasło zostało zmienione</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showChangeEmailToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <p className="text-sm">E-mail został zmieniony</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showChangeUsernameToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <p className="text-sm">Nazwa użytkownika została zmieniona</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showSetPasswordToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <p className="text-sm">Hasło zostało ustawione</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  const showPagePlacementToast = (text: string, iconSource: string) => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <Image src={iconSource} alt="icon" width={20} height={20} />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">{text}</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const deletedPostToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <TrashIcon className="w-5 h-5 !stroke-gray-950 stroke-2" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">Wrzuta usunięta</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 4000,
      },
    );
  };

  const showCommentAddedToast = () => {
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <Comments className="w-5 h-5 !stroke-gray-950" />
          <Divider type="vertical" className="bg-gray-950" />
          <p className="text-sm">{randomToast.text}</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  return {
    showErrorToast,
    showLogoutToast,
    deletedPostToast,
    showHideCommentToast,
    showContactFormToast,
    showChangeEmailToast,
    showSetPasswordToast,
    showCommentAddedToast,
    showBlockCommentToast,
    showSuccessLoginToast,
    showDeleteCommentToast,
    showCommentReportToast,
    showPagePlacementToast,
    showChangeUsernameToast,
    showChangePasswordToast,
  };
};
