import { createContext, type FC, type ReactNode, useContext, useMemo, useState } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

export type SetupContextState = {
  isGoogleAdSetupReady: boolean;
  isSmartAdSetupReady: boolean;
  isInterstitialAd: boolean;
  firebaseConfig: RemoteConfig | null;
  setSmartAdSetupReady: (value: boolean) => void;
  setGoogleAdSetupReady: (value: boolean) => void;
  setInterstitialAd: (value: boolean) => void;
  setFirebaseConfig: (value: RemoteConfig | null) => void;
};

export type SetupContextProviderProps = {
  children: ReactNode;
};

const SetupContext = createContext<SetupContextState | null>(null);

export const SetupContextProvider: FC<SetupContextProviderProps> = ({ children }) => {
  const [isSmartAdSetupReady, setSmartAdSetupReady] = useState<boolean>(false);
  const [isGoogleAdSetupReady, setGoogleAdSetupReady] = useState<boolean>(false);
  const [isInterstitialAd, setInterstitialAd] = useState<boolean>(false);
  const [firebaseConfig, setFirebaseConfig] = useState<RemoteConfig | null>(null);

  const value = useMemo(
    () => ({
      isGoogleAdSetupReady,
      isSmartAdSetupReady,
      isInterstitialAd,
      firebaseConfig,
      setGoogleAdSetupReady,
      setSmartAdSetupReady,
      setInterstitialAd,
      setFirebaseConfig,
    }),
    [
      isGoogleAdSetupReady,
      isSmartAdSetupReady,
      isInterstitialAd,
      firebaseConfig,
      setGoogleAdSetupReady,
      setSmartAdSetupReady,
      setFirebaseConfig,
      setInterstitialAd,
    ],
  );

  return <SetupContext.Provider value={value}>{children}</SetupContext.Provider>;
};

SetupContextProvider.displayName = 'SetupContextProvider';

export const useSetupContext = () => {
  const context = useContext(SetupContext);

  if (context === null) throw new Error('useSetupContext must be used within a SetupContextProvider');

  return context;
};
