/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

import { parseAdGlobalRefreshRate, refreshSmartAd } from '../utils';
import { useSetupContext } from '../contexts/SetupContext';

interface IGlobalRefreshRateProps {
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const useGlobalRefreshRate = ({ getRemoteConfigValue }: IGlobalRefreshRateProps) => {
  const { firebaseConfig } = useSetupContext();
  const [globalSmartRefreshRate, setGlobalSmartRefreshRate] = useState<number>(0);
  const [globalGoogleRefreshRate, setGlobalGoogleRefreshRate] = useState<number>(0);

  const setSmartRefreshInterval = (adId: string, localRefreshRate: number | undefined) => {
    if (!globalSmartRefreshRate && !localRefreshRate) return undefined;
    const refreshInterval = localRefreshRate || globalSmartRefreshRate;
    const refreshTime = refreshInterval * 1000; // in seconds
    return setInterval(() => refreshSmartAd(adId), refreshTime);
  };

  const setGoogleRefreshInterval = (
    adId: string,
    localRefreshRate: number | undefined,
    pageName: string | undefined,
  ) => {
    if (!globalGoogleRefreshRate && !localRefreshRate) return undefined;
    const refreshInterval = localRefreshRate || globalGoogleRefreshRate;
    const refreshTime = refreshInterval * 1000;

    const refresh = () => {
      window.googletag
        .pubads()
        .getSlots()
        .forEach((slot: any) => {
          const id = slot.getSlotElementId();
          if (id === pageName) {
            window.googletag.cmd.push(() => {
              console.log('gam_refresh_start', adId);
              window.googletag.pubads().refresh([slot]);
            });
          }
        });
    };

    return setInterval(() => refresh(), refreshTime);
  };

  useEffect(() => {
    if (!firebaseConfig) return;
    const fetchRefreshRate = async () => {
      try {
        const value = await getRemoteConfigValue(firebaseConfig, 'AdGlobalRefreshRate');
        if (!value) return;
        const parsed = parseAdGlobalRefreshRate(value);
        setGlobalSmartRefreshRate(parsed.smart_pagedefined ?? 0);
        setGlobalGoogleRefreshRate(parsed.google_pagedefined ?? 0);
      } catch (error) {
        console.error(error);
      }
    };

    void fetchRefreshRate();
  }, [firebaseConfig]);

  return { globalSmartRefreshRate, globalGoogleRefreshRate, setSmartRefreshInterval, setGoogleRefreshInterval };
};
