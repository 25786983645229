import { ICategoryData } from '@/api/categories/interfaces';

export const sortCategories = (categoriesData: ICategoryData[]) => {
  const mainCategories: ICategoryData[] = [];
  const temporaryCategories: ICategoryData[] = [];

  categoriesData.forEach((category) => {
    const { isConstant } = category.attributes;

    if (!isConstant) {
      temporaryCategories.push(category);
    } else {
      mainCategories.push(category);
    }
  });

  return [...temporaryCategories, ...mainCategories];
};
