import { cva, type VariantProps } from 'class-variance-authority';
import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';

type AdPlaceholderProps = VariantProps<typeof placeholderVariants>;

const placeholderVariants = cva(
  'md:border md:border-gray-800 rounded-xl justify-center items-center self-center bg-gray-950 relative h-full absolute z-0 top-0',
  {
    variants: {
      variant: {
        topNavbar: 'min-h-180 md:min-h-250',
        sidebarBot: 'min-h-600',
        stickedBanner: '',
        sidebarTop: '',
        inFeed: '',
        belowPager: '',
        abovePager: '',
        outstreamVideo: '',
      },
    },
  },
);

export const AdPlaceholder = ({ variant }: AdPlaceholderProps) => (
  <Wrapper className={placeholderVariants({ variant })} fullWidth>
    <Image alt="placeholder" src="/images/ad-placeholder.svg" width={64} height={64} />
  </Wrapper>
);
