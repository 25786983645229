import clsx from 'clsx';

interface ITrashIconProps {
  className?: string;
}

export const TrashIcon = ({ className }: ITrashIconProps) => (
  <svg
    className={clsx('stroke-white', className)}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M2.66675 4.66667H13.3334M12.6667 4.66667L12.0887 12.7613C12.0648 13.0977 11.9143 13.4125 11.6675 13.6424C11.4207 13.8722 11.096 14 10.7587 14H5.24141C4.90417 14 4.57946 13.8722 4.33267 13.6424C4.08588 13.4125 3.93536 13.0977 3.91141 12.7613L3.33341 4.66667H12.6667ZM10.0001 4.66667V2.66667C10.0001 2.48986 9.92984 2.32029 9.80482 2.19526C9.6798 2.07024 9.51023 2 9.33341 2H6.66675C6.48994 2 6.32037 2.07024 6.19534 2.19526C6.07032 2.32029 6.00008 2.48986 6.00008 2.66667V4.66667H10.0001Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
