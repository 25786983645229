/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Cookies from 'universal-cookie';

export const useUserCookies = () => {
  const cookies = new Cookies();
  const accountCreationDate = cookies.get('userInfo')?.createdAt as string;
  const username = cookies.get('userInfo')?.username as string;
  const avatar = cookies.get('userInfo')?.avatar as string;
  const email = cookies.get('userInfo')?.email as string;
  const id = cookies.get('userInfo')?.id as number;

  const setUserCookies = (
    id: number,
    email: string,
    username: string,
    createdAt: string,
    isRememberMe: boolean,
    avatar?: string,
  ) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);

    cookies.set(
      'userInfo',
      {
        id,
        email,
        username,
        createdAt,
        avatar,
      },
      { path: '/', expires: isRememberMe ? expirationDate : undefined },
    );
  };

  const removeUserCookies = () => {
    cookies.remove('userInfo', { path: '/' });
  };

  return { setUserCookies, removeUserCookies, id, email, username, accountCreationDate, avatar };
};
