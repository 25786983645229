import { fetchAndActivate, getRemoteConfig, getValue, isSupported, RemoteConfig } from 'firebase/remote-config';

import { firebaseApp } from './config';

export const RemoteConfigValueKey = Object.freeze({
  ListTemplatesInfo: 'ListTemplatesInfo',
  AdGlobalRefreshRate: 'AdGlobalRefreshRate',
  AdProviderInitSmart: 'AdProviderInitSmart',
});

const remoteConfig = isSupported().then((yes) => (yes ? getRemoteConfig(firebaseApp) : null));

export const getFirebaseRemoteConfig = async () => {
  const config = await remoteConfig;

  if (!config) return null;

  try {
    await fetchAndActivate(config);
    return config;
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const getRemoteConfigValue = async (config: RemoteConfig, key: string) => {
  if (!config) return null;

  try {
    await fetchAndActivate(config);
    return getValue(config, key).asString();
  } catch (e) {
    console.error(e);
  }

  return null;
};
