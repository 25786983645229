import { ReactNode } from 'react';
import { EmptySmartAdsProvider } from './EmptyAdsContext';
import { SetupContextProvider } from './SetupContext';
import { DisplayedAdsProvider } from './DisplayedAdsContext';

export type AdsContextProviderProps = {
  children: ReactNode;
};

export const AdsContextProvider = ({ children }: AdsContextProviderProps) => (
  <SetupContextProvider>
    <DisplayedAdsProvider>
      <EmptySmartAdsProvider>{children}</EmptySmartAdsProvider>
    </DisplayedAdsProvider>
  </SetupContextProvider>
);
