/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

export const authorizedUserInstance = axios.create({
  baseURL: process.env.API_URL,
  timeout: 360000, // Set the timeout to 6 minutes
});

const onRequest = (config: AxiosRequestConfig): any => {
  const cookies = new Cookies();
  const tokenValue = cookies.get('authData')?.token as string;

  const { headers } = config;

  if (headers) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${tokenValue}`,
      },
    };
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

authorizedUserInstance.interceptors.request.use(onRequest, onRequestError);
authorizedUserInstance.interceptors.response.use(onResponse, onResponseError);
