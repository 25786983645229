/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

import { useSetupContext } from '../contexts/SetupContext';

interface IFeedAdConfig {
  count: number;
  start: number;
  offset: number;
  prefetch: number;
}

interface IAdZoneValueProps {
  pageName: string;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const useAdZoneValue = ({ pageName, getRemoteConfigValue }: IAdZoneValueProps) => {
  const { firebaseConfig } = useSetupContext();
  const [adZoneOrder, setAdZoneOrder] = useState<string[]>([]);
  const [feedAdConfig, setFeedAdConfig] = useState<IFeedAdConfig | undefined>(undefined);
  const [adZoneSmartRefreshRate, setAdZoneSmartRefreshRate] = useState<number | undefined>(undefined);
  const [adZoneGoogleRefreshRate, setAdZoneGoogleRefreshRate] = useState<number | undefined>(undefined);
  const adZone = `AdZone${pageName}`;
  const isFeedAd = pageName.endsWith('in_feed');

  useEffect(() => {
    if (!firebaseConfig) return;
    const fetchRefreshRate = async () => {
      try {
        const value = await getRemoteConfigValue(firebaseConfig, adZone);
        if (!value) return;

        const parsedData = JSON.parse(value);
        const orderArray = !!parsedData?.order && parsedData?.order?.split(';');
        const feedOrderArray = !!parsedData?.children && parsedData?.children[0]?.order?.split(';');
        setAdZoneSmartRefreshRate(parsedData?.refreshRate?.smart_pagedefined);
        setAdZoneGoogleRefreshRate(parsedData?.refreshRate?.gam_pagedefined);
        setAdZoneOrder(isFeedAd ? feedOrderArray : orderArray);
        setFeedAdConfig(parsedData);
      } catch (error) {
        console.error(error);
      }
    };

    void fetchRefreshRate();
  }, [firebaseConfig]);

  return { adZoneOrder, adZoneSmartRefreshRate, adZoneGoogleRefreshRate, feedAdConfig };
};
