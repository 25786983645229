import React, { forwardRef, useEffect, useState } from 'react';

import { RemoteConfig } from 'firebase/remote-config';

import { useAdCall } from '../hooks/useAdCall';
import { useSetupContext } from '../contexts/SetupContext';

interface BackgroundAdProps {
  pageName: string;
  isConsentNoticeReady: boolean;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const BackgroundAd = forwardRef<HTMLDivElement, BackgroundAdProps>(
  ({ pageName, isConsentNoticeReady, getRemoteConfigValue }, ref) => {
    const { isSmartAdSetupReady, firebaseConfig } = useSetupContext();
    const { smartAdId, smartAdCall, fetchSmartProvider } = useAdCall({
      pageName,
      isConsentNoticeReady,
      getRemoteConfigValue,
    });
    const [isAdVisible, setAdVisible] = useState<boolean>(false);

    const fetchIsBackgroundAd = async (config: RemoteConfig) => {
      try {
        const response = await getRemoteConfigValue(config, 'isBackgroundAd');
        const isBackgroundAd: boolean = JSON.parse(response || '');
        setAdVisible(isBackgroundAd || false);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (!firebaseConfig || !isConsentNoticeReady) return;
      void fetchSmartProvider(firebaseConfig);
      void fetchIsBackgroundAd(firebaseConfig);
    }, [isConsentNoticeReady, firebaseConfig]);

    useEffect(() => {
      if (isConsentNoticeReady && smartAdId && isSmartAdSetupReady && isAdVisible) {
        smartAdCall();
      }
    }, [isConsentNoticeReady, smartAdId, isSmartAdSetupReady, isAdVisible]);

    return (
      <div
        className="!absolute top-[111px] left-0 w-full h-[calc(100vh-111px)] z-0 hidden desktop:block"
        id="layoutContent"
      >
        <div id={smartAdId} />
      </div>
    );
  },
);
